import styled from 'styled-components';

import {S2} from '@/componentLibrary/components/typography';
import {
    BorderRadiusBaseMedium,
    ColorBaseGreen500,
    ColorBaseGrey200,
    ColorBgSubtle,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import {InnerHTML} from '@/pages/Organization/pages/Assessment/pages/CodingTest/functions';

export const ContentItemWrapper = styled.div`
    display: flex;
    gap: ${SpacingXlarge};
`;

export const Label = styled(S2)`
    width: 278px;
    flex-shrink: 0;
    padding: ${SpacingSmall} 0;
`;

export const Divider = styled.div`
    border-bottom: 1px solid ${ColorBaseGrey200};
    margin-block: ${SpacingXxsmall};
`;

export const StyledScorecard = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};
    padding-top: ${SpacingXlarge};
`;

export const ScorecardWrapper = styled.div`
    border-radius: ${BorderRadiusBaseMedium};
    background-color: ${ColorBgSubtle};
    margin-inline: -${SpacingMedium};
    padding-inline: ${SpacingMedium};
`;

export const ScorecardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
`;

export const BodyWrapper = styled.div`
    padding: ${SpacingLarge} ${SpacingXlarge} ${SpacingXsmall};
    overflow-y: auto;
`;

export const StyledIcon = styled.div`
    display: none;
    position: absolute;
    top: ${SpacingXsmall};
    right: 0;
`;

export const ContentItem = styled.div`
    width: 100%;
    position: relative;
    cursor: default;
    padding: ${SpacingSmall} 0;

    &:hover {
        background-color: ${ColorBaseGreen500};

        ${StyledIcon} {
            display: block;
        }
    }
`;

export const StyledInnerHTML = styled(InnerHTML)`
    display: block;
    width: 95%;
`;
