import styled from 'styled-components';

import {BorderBaseMutedRule} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    ColorBgSubtle,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall
} from '@/componentLibrary/tokens/variables';

export const ArticleWrapper = styled.div`
    ${BorderBaseMutedRule}
    background-color: ${ColorBgSubtle};
    width: 320px;
    cursor: pointer;
    padding: ${SpacingLarge} ${SpacingSmall} ${SpacingMedium};

    &:hover {
        filter: drop-shadow(0 2px 3px rgb(83 83 83 / 30%));
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        height: 148px;
        width: 100%;
    }
`;

export const InnerWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
