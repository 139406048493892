export enum PublicExperimentalFeatureKeys {
    ALL_CANDIDATES_PAGE = 'ALL_CANDIDATES_PAGE',
    JOB_POSITION_REPORTS = 'JOB_POSITION_REPORTS'
}

type PublicExperimentalFeatureKey = keyof typeof PublicExperimentalFeatureKeys;
export type PublicExperimentalFeatures = {
    [k in PublicExperimentalFeatureKey]: boolean;
};

export enum PrivateExperimentalFeatureKeys {
    REAL_TIME_UPDATES = 'REAL_TIME_UPDATES',
    ROLE_FIT_PAGE_CHART_HEADER = 'ROLE_FIT_PAGE_CHART_HEADER',
    API_TOKEN_EXPIRATION_TIME = 'API_TOKEN_EXPIRATION_TIME',
    TREX_SQUAD_ACTIVE_DEV = 'TREX_SQUAD_ACTIVE_DEV',
    BETA_COMBINED_ROLE_FIT = 'BETA_COMBINED_ROLE_FIT',
    READINESS_DATA_COLLECTION = 'READINESS_DATA_COLLECTION'
}

export type PrivateExperimentalFeatureKey = keyof typeof PrivateExperimentalFeatureKeys;
export type PrivateExperimentalFeatures = {
    [k in PrivateExperimentalFeatureKey]: boolean;
};

export type ExperimentalFeatureKey =
    | PublicExperimentalFeatureKey
    | PrivateExperimentalFeatureKey;

export type ExperimentalFeatureKeys =
    | PublicExperimentalFeatureKeys
    | PrivateExperimentalFeatureKeys;

export enum OrganizationFeatureKeys {
    SOME_FEATURE = 'SOME_FEATURE'
}

type OrganizationFeatureKey = keyof typeof OrganizationFeatureKeys;
export type OrganizationFeatures = {
    [k in OrganizationFeatureKey]: boolean;
};
