import {Separator} from '@/componentLibrary/components/Separator';
import {
    PlainButton,
    PlainNavLinkButton
} from '@/componentLibrary/components/buttons/PlainButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {Caption, H2, P1, P2} from '@/componentLibrary/components/typography';
import {LinkedInDataCollectionDetailedInformation} from '@/pages/ReadinessDataCollection/components/LinkedinDataCollectionDetailedInformation';
import {RecommendationRelevanceForm} from '@/pages/User/components/RecommendationRelevanceForm';
import {userLinks} from '@/pages/User/links';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useFindMoreJobOpportunitiesEULogic} from './logic';
import {ActionRow, Actions, Wrapper} from './styled';
import {Props} from './types';

export function FindMoreJobOpportunitiesEU({
    userId,
    organizationId,
    forDataCollectionView
}: Props) {
    const {
        phrases,
        linkedInCaption,
        detailedInformationModalLogic,
        relevanceFormModalLogic,
        relevanceParametersOptions,
        userRelevanceInformation
    } = useFindMoreJobOpportunitiesEULogic(userId);

    if (relevanceFormModalLogic.modalIsOpen) {
        return (
            <RecommendationRelevanceForm
                close={relevanceFormModalLogic.closeModal}
                userId={userId}
                relevanceParametersOptions={relevanceParametersOptions}
                userRelevanceInformation={userRelevanceInformation}
                onSubmit={relevanceFormModalLogic.closeModal}
            />
        );
    }

    const sharedContentSection = (
        <>
            <H2>{phrases.title}</H2>
            <P1>{phrases.description}</P1>
            <Caption>{linkedInCaption}</Caption>
        </>
    );

    const sharedActionRow = (
        <ActionRow>
            <SecondaryButton>
                <Icon icon={Icons.LINKEDIN_LOGO} />
                {phrases.retrieveLinkedInData}
            </SecondaryButton>
            <PlainButton onClick={relevanceFormModalLogic.openModal}>
                {phrases.addManually}
            </PlainButton>
        </ActionRow>
    );

    const dataCollectionViewActionRow = isDefined(organizationId) && (
        <>
            <Separator>
                <P2>{phrases.or}</P2>
            </Separator>
            <ActionRow>
                <PlainNavLinkButton to={userLinks.jobApplication(organizationId)}>
                    {phrases.maybeLater}
                </PlainNavLinkButton>
            </ActionRow>
        </>
    );

    const actionsSection = (
        <Actions>
            {sharedActionRow}
            {forDataCollectionView && dataCollectionViewActionRow}
        </Actions>
    );

    const detailedInformationModalSection = detailedInformationModalLogic.modalIsOpen && (
        <LinkedInDataCollectionDetailedInformation
            close={detailedInformationModalLogic.closeModal}
        />
    );

    return (
        <Wrapper>
            {sharedContentSection}
            {actionsSection}
            {detailedInformationModalSection}
        </Wrapper>
    );
}
