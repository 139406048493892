import {H3, P1} from '@/componentLibrary/components/typography';
import {SpacingSmall} from '@/componentLibrary/tokens/variables';
import {WhiteBox} from '@/pages/SignupV2/styled';
import styled, {css} from 'styled-components';

const textAlignCenter = css`
    text-align: center;
`;

export const CenteredWhiteBox = styled(WhiteBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: ${SpacingSmall};
    padding-bottom: ${SpacingSmall};
`;

export const CenteredH3 = styled(H3).attrs({as: 'div'})`${textAlignCenter}`;
export const CenteredP1 = styled(P1).attrs({as: 'div'})`${textAlignCenter}`;
