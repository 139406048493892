import {defineMessages} from 'react-intl';

export default defineMessages({
    modalTitle: {
        id: 'app.readinessDataCollectionCTA.legalModal.title'
    },
    description: {
        id: 'app.readinessDataCollectionCTA.legalModal.description'
    },
    tableHeaderLinkedinData: {
        id: 'app.readinessDataCollectionCTA.legalModal.collectedDataTable.header.LinkedInData'
    },
    tableHeaderDescription: {
        id: 'app.readinessDataCollectionCTA.legalModal.collectedDataTable.header.description'
    },
    tableProfileInfoTitle: {
        id: 'app.readinessDataCollectionCTA.legalModal.collectedDataTable.profileInfo.title'
    },
    tableProfileInfoDescription: {
        id: 'app.readinessDataCollectionCTA.legalModal.collectedDataTable.profileInfo.description'
    },
    tableJobPrefsTitle: {
        id: 'app.readinessDataCollectionCTA.legalModal.collectedDataTable.jobPrefs.title'
    },
    tableJobPrefsDescription: {
        id: 'app.readinessDataCollectionCTA.legalModal.collectedDataTable.jobPrefs.description'
    },
    tableNetworkingTitle: {
        id: 'app.readinessDataCollectionCTA.legalModal.collectedDataTable.networking.title'
    },
    tableNetworkingDescription: {
        id: 'app.readinessDataCollectionCTA.legalModal.collectedDataTable.networking.description'
    },
    faq: {
        id: 'app.readinessDataCollectionCTA.legalModal.FAQ'
    },
    consentPolicyCaption: {
        id: 'app.readinessDataCollectionCTA.legalModal.consentPolicyCaption'
    }
});
