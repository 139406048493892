import styled from 'styled-components';

import {SpacingLarge, SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    & > &:not(:last-child) {
        margin-bottom: ${SpacingLarge};
    }
`;

export const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;

    & > &:last-child {
        text-align: center;
        margin-top: ${SpacingXsmall};
    }
`;
