import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorBaseGrey600, ColorFgDefault} from '@/componentLibrary/tokens/variables';

import {Wrapper} from './Wrapper';
import {SORTING_MODE} from './constants';
import {SortIconProps} from './types';

export const SortIcon = ({mode}: SortIconProps) => {
    const ascFill = mode === SORTING_MODE.ASC ? ColorFgDefault : ColorBaseGrey600;
    const descFill = mode === SORTING_MODE.DESC ? ColorFgDefault : ColorBaseGrey600;

    return (
        <Wrapper>
            <Icon
                icon={Icons.ARROW_DROP_UP}
                fill={ascFill}
                hoverFill={ascFill}
                size={36}
                isHoverable
            />
            <Icon
                icon={Icons.ARROW_DROP_DOWN}
                fill={descFill}
                hoverFill={descFill}
                size={36}
                isHoverable
            />
        </Wrapper>
    );
};
