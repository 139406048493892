import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.readinessDataCollectionCTA.title'
    },
    description: {
        id: 'app.readinessDataCollectionCTA.description'
    },
    linkedInCaption: {
        id: 'app.readinessDataCollectionCTA.linkedInCaption'
    },
    seeDetailedInformation: {
        id: 'app.readinessDataCollectionCTA.linkedInCaption.seeDetailedInformation'
    },
    retrieveLinkedInData: {
        id: 'app.readinessDataCollectionCTA.action.retrieveLI'
    },
    addManually: {
        id: 'app.readinessDataCollectionCTA.action.addManually'
    },
    or: {
        id: 'app.general.or'
    },
    maybeLater: {
        id: 'app.general.maybeLater'
    }
});
