import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {OtherCaption} from '@/componentLibrary/tokens/typography';
import {SpacingLarge, SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    max-width: 640px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${SpacingLarge};
    align-self: stretch;
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXsmall};
    
    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        width: 100%;
    }
`;

export const ActionRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SpacingXsmall};
    align-self: center;
    
    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        flex-direction: column;
        width: 100%;
    }
`;

export const CustomPlainButton = styled(PlainButton)`
    padding: 0;
    margin: 0;
    ${OtherCaption};
`;
