import styled from 'styled-components';

import {
    ColorBgDefault,
    SpacingLarge,
    SpacingMedium
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${SpacingMedium};
    gap: ${SpacingLarge};
    min-width: 324px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(255 255 255 / 0%) 0%, ${ColorBgDefault} 100%);
    }
 
`;
