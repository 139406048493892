import {useLoader} from '../hooks';
import {Bar, ProgressBar} from '../styled';

const INTERVAL_DURATION = 100;
const INCREMENT_FACTOR = 10;
const MAX_PERCENTAGE = 97;

export const Loader = ({initialState}: {initialState?: number}) => {
    const {percentage} = useLoader({
        intervalDuration: INTERVAL_DURATION,
        incrementFactor: INCREMENT_FACTOR,
        maxPercentage: MAX_PERCENTAGE,
        initialState
    });

    return (
        <ProgressBar>
            <Bar percentage={percentage} intervalDuration={INTERVAL_DURATION} />
        </ProgressBar>
    );
};
