import styled from 'styled-components';

import {ModalBase} from '@/componentLibrary/blocks/Modals/Modal/styled';
import {SubtitleS1} from '@/componentLibrary/tokens/typography';
import {BorderRadiusBaseLarge, SpacingLarge} from '@/componentLibrary/tokens/variables';

import {S2} from '../typography';
import {Banner} from './Banner';
import {InnerWrapper} from './styled';
import {BannerProps} from './types';

const StyledPromoBanner = styled(Banner)`
    border: 0;
    border-radius: 0;

    ${InnerWrapper} {
        padding: ${SpacingLarge};
    }

    ${S2} {
        ${SubtitleS1}
    }

    ${ModalBase} & {
        border-top-left-radius: ${BorderRadiusBaseLarge};
        border-top-right-radius: ${BorderRadiusBaseLarge};
    }
`;

export function PromoBanner(props: BannerProps) {
    return <StyledPromoBanner isPromo {...props} />;
}
