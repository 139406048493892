import {TableOptions, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {CheckBox} from '../controls/CheckBox';
import {TextSkeleton} from '../skeleton/TextSkeleton';
import {TABLE_CELL_WIDTH} from './constants';
import {TableComponentOptions} from './types';
import {getHeaderCheckboxValue, getRowCheckboxValue} from './utils';

type UseTableProps<T> = {
    loading?: boolean;
    columns: TableOptions<T>['columns'];
    options: TableComponentOptions<T>;
    data: T[];
    onSelectedRowsChange?: (selectedRows: T[]) => void;
};

export function useTable<T>({loading, columns, options, data}: UseTableProps<T>) {
    const [rowSelection, setRowSelection] = useState({});
    const tableData = useMemo(
        () => (loading ? (Array(data.length || 20).fill({}) as T[]) : data),
        [loading, data]
    );
    const isSelectable = !!options?.enableRowSelection;

    const tableColumns = useMemo(() => {
        const updatedColumns = loading
            ? columns.map(column => ({
                  ...column,
                  cell: column.meta?.cellLoading ?? (() => <TextSkeleton />)
              }))
            : columns;
        if (isSelectable) {
            updatedColumns.unshift({
                id: 'select',
                header: ({table}) => (
                    <CheckBox
                        {...{
                            value: getHeaderCheckboxValue(table),
                            onChange: (checked: boolean) =>
                                table.toggleAllPageRowsSelected(checked)
                        }}
                    />
                ),
                cell: ({row}) => (
                    <CheckBox
                        {...{
                            value: getRowCheckboxValue(row),
                            isDisabled: !row.getCanSelect(),
                            onChange: (checked: boolean) => row.toggleSelected(checked)
                        }}
                    />
                ),
                meta: {
                    width: TABLE_CELL_WIDTH.MINIMAL
                }
            });
        }
        return updatedColumns;
    }, [loading, columns, isSelectable]);

    const memoizedSetRowSelection = useCallback(setRowSelection, []);

    if (isSelectable) {
        options = {
            ...options,
            state: {...options.state, rowSelection},
            onRowSelectionChange: memoizedSetRowSelection
        };
    }

    const table = useReactTable<T>({
        data: tableData,
        columns: tableColumns,
        enableSorting: false,
        enableSortingRemoval: false,
        getCoreRowModel: getCoreRowModel(),
        ...options
    });

    const previousSelectedRows = useRef<T[] | null>(null);
    const selectedRows = table.getSelectedRowModel().rows;

    useEffect(() => {
        let isMounted = true;
        if (isSelectable) {
            const rows = selectedRows.map(row => row.original);
            if (
                !previousSelectedRows.current ||
                JSON.stringify(previousSelectedRows.current) !== JSON.stringify(rows)
            ) {
                previousSelectedRows.current = rows;
                if (isMounted) {
                    options.meta?.onSelectedRowsChange?.(rows);
                }
            }
        }

        return () => {
            isMounted = false;
        };
    }, [isSelectable, options.meta, selectedRows]);

    return table;
}
