import {ReactNode} from 'react';

import {Caption, P1} from '@/componentLibrary/components/typography';
import {ColorBaseGrey700} from '@/componentLibrary/tokens/variables';
import {onBaseFive, roundValue} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';

const validateScore = (score?: number | null) => {
    if (isDefined(score) && score >= 0 && score <= 1) {
        return true;
    }
    console.error('Score must be between 0 and 1');
    return false;
};

export const scoreExists = (score?: number | null) => isDefined(score) && validateScore(score);

export const getScoreInPercentage = (scoreToFormat: number) => roundValue(scoreToFormat * 100);

export const renderText = (children: ReactNode, isLargeTitle: boolean) =>
    isLargeTitle ? <P1>{children}</P1> : <p>{children}</p>;

export const renderScore = (scoreToShow: number, byFive: boolean, isLargeTitle: boolean) => {
    const formattedScore = byFive
        ? onBaseFive(scoreToShow)
        : getScoreInPercentage(scoreToShow);
    const suffix = byFive ? '/5' : '/100';

    return (
        <>
            {renderText(formattedScore, isLargeTitle)}
            <Caption color={ColorBaseGrey700}>{suffix}</Caption>
        </>
    );
};
