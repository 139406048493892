import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {ModalBody} from '@/componentLibrary/blocks/Modals/Modal/styled';
import {P2} from '@/componentLibrary/components/typography';
import {MarkdownSupportedBodyTypes} from '@/componentLibrary/components/typography/Markdown';
import {SpacingLarge} from '@/componentLibrary/tokens/variables';
import {getConfig} from '@/config';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {LINKS} from '@/links';
import {useIntl} from 'react-intl';
import {CollectedDataTable} from './components/CollectedDataTable';
import messages from './messages';
import {Paragraph} from './styled';
import {Props} from './types';

export function LinkedInDataCollectionDetailedInformation({close}: Props) {
    const intl = useIntl();
    const phrases = useExtractPhraseConstants(messages);
    const privacyPolicyUrl = `${getConfig().APP}${LINKS.privacyPolicy}`;

    return (
        <Modal close={close} headerTitle={phrases.modalTitle} withoutBody>
            <ModalBody $padding={SpacingLarge}>
                <Paragraph body={MarkdownSupportedBodyTypes.P2}>
                    {phrases.description}
                </Paragraph>
                <CollectedDataTable />
                <Paragraph body={MarkdownSupportedBodyTypes.P2}>{phrases.faq}</Paragraph>
                <P2>
                    {intl.formatMessage(messages.consentPolicyCaption, {
                        supportEmail: (
                            <a href="mailto:support@alvalabs.io">support@alvalabs.io</a>
                        ),
                        privacyPolicy: <a href={privacyPolicyUrl}>privacy policy</a>
                    })}
                </P2>
            </ModalBody>
        </Modal>
    );
}
