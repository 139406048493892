import {FormEvent, ReactComponentElement, useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {H4} from '@/componentLibrary/components/typography';
import {SpacingSmall} from '@/componentLibrary/tokens/variables';
import {getConfig} from '@/config';
import {useFormValidator} from '@/pages/SignupV2/components/Email/CreateFreeTrialForm/logic';
import {UserExistsBanner} from '@/pages/SignupV2/components/UserExistsBanner';
import {FieldState} from '@/pages/SignupV2/components/types';
import {SizeWide} from '@/pages/SignupV2/constants';
import {
    CreateFreeTrialData,
    CreationResult,
    useCreateFreeTrialLogic
} from '@/pages/SignupV2/hooks/useCreateFreeTrialLogic';
import {WhiteBox} from '@/pages/SignupV2/styled';

import messages from './messages';

type Props = {
    onEmailSent: (emailAddress: string, userId?: number) => void;
};

export function CreateFreeTrialForm(props: Props) {
    const intl = useIntl();

    const [data, setData] = useState<CreateFreeTrialData>({
        firstName: '',
        lastName: '',
        organizationName: '',
        email: '',
        phoneNumber: '',
        referralSource: ''
    });

    const [emailBlurred, setEmailBlurred] = useState(false);

    const {createFreeTrial, result, userId} = useCreateFreeTrialLogic(data);

    const {fieldStates, disableSubmit, blacklistedDomain, handleInvalidSubmit} =
        useFormValidator(data, emailBlurred);

    const getUserExistsBanner = useCallback((): ReactComponentElement<
        typeof UserExistsBanner
    > => {
        return <UserExistsBanner email={data.email} />;
    }, [data.email]);

    const getMessageBanner = useCallback(
        (title, msg): ReactComponentElement<typeof Banner> => {
            return (
                <BannerWrap>
                    <Banner
                        type={BannerType.ATTENTION}
                        noIcon
                        title={<FormattedMessage {...title} />}
                    >
                        <FormattedMessage {...msg} />
                    </Banner>
                </BannerWrap>
            );
        },
        []
    );

    useEffect(() => {
        if (result === CreationResult.OK) {
            props.onEmailSent(data.email, userId);
        }
    }, [result, data.email, userId, props]);

    let banner = null;
    if (result === CreationResult.USER_ALREADY_ADMIN_IN_ORGANIZATION) {
        banner = getUserExistsBanner();
    } else if (result === CreationResult.RECAPTCHA_TOKEN_ERROR) {
        banner = getMessageBanner(messages.warning, messages.errorRecaptcha);
    }

    const handleFieldChange = (e: FormEvent<HTMLInputElement>) => {
        const {value, name} = e.currentTarget;
        const changedData = {...data, [name]: value};
        if (name === 'email') {
            setEmailBlurred(false);
        }
        setData(changedData);
    };

    const handleEmailBlur = () => {
        setEmailBlurred(true);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (disableSubmit) {
            handleInvalidSubmit();
            return;
        }

        typeof grecaptcha !== 'undefined' &&
            grecaptcha.ready(function () {
                grecaptcha
                    .execute(getConfig().RECAPTCHA_SITE_KEY, {action: 'submit'})
                    .then(function (token: string) {
                        createFreeTrial(token);
                    });
            });
    };

    let emailErrorMessage = intl.formatMessage(messages.emailError);
    if (blacklistedDomain) {
        emailErrorMessage = intl.formatMessage(messages.emailBlacklistedError, {
            domain: blacklistedDomain
        });
    }

    const isClearable = false;
    return (
        <WhiteBox>
            <Title>
                <FormattedMessage {...messages.title} />
            </Title>
            {banner}
            <form id="create-free-trial-from-email" onSubmit={handleSubmit}>
                <NamesWrapper>
                    <NameItem>
                        <FormField>
                            <TextField
                                type="text"
                                isRequired
                                fullWidth
                                name="firstName"
                                value={data.firstName}
                                isClearable={isClearable}
                                onChange={handleFieldChange}
                                errorMessage={intl.formatMessage(messages.firstNameError)}
                                hasError={fieldStates['firstName'] === FieldState.INVALID}
                                label={<FormattedMessage {...messages.firstNameLabel} />}
                                placeholder={' '}
                            />
                        </FormField>
                    </NameItem>
                    <NameItem>
                        <FormField>
                            <TextField
                                type="text"
                                isRequired
                                fullWidth
                                name="lastName"
                                value={data.lastName}
                                isClearable={isClearable}
                                onChange={handleFieldChange}
                                errorMessage={intl.formatMessage(messages.lastNameError)}
                                hasError={fieldStates['lastName'] === FieldState.INVALID}
                                label={<FormattedMessage {...messages.lastNameLabel} />}
                                placeholder={' '}
                            />
                        </FormField>
                    </NameItem>
                </NamesWrapper>
                <FormField>
                    <TextField
                        fullWidth
                        type="email"
                        isRequired
                        name="email"
                        value={data.email}
                        isClearable={isClearable}
                        onChange={handleFieldChange}
                        onBlur={handleEmailBlur}
                        errorMessage={emailErrorMessage}
                        hasError={fieldStates['email'] === FieldState.INVALID}
                        label={<FormattedMessage {...messages.emailLabel} />}
                        placeholder={' '}
                    />
                </FormField>
                <FormField>
                    <TextField
                        fullWidth
                        type="tel"
                        isRequired
                        name="phoneNumber"
                        value={data.phoneNumber}
                        isClearable={isClearable}
                        onChange={handleFieldChange}
                        errorMessage={intl.formatMessage(messages.phoneError)}
                        hasError={fieldStates['phoneNumber'] === FieldState.INVALID}
                        label={<FormattedMessage {...messages.phoneLabel} />}
                        helpText={<FormattedMessage {...messages.phoneDisclaimer} />}
                        placeholder={' '}
                    />
                </FormField>
                <FormField>
                    <TextField
                        fullWidth
                        type="text"
                        isRequired
                        name="organizationName"
                        value={data.organizationName}
                        isClearable={isClearable}
                        onChange={handleFieldChange}
                        errorMessage={intl.formatMessage(messages.orgNameError)}
                        hasError={fieldStates['organizationName'] === FieldState.INVALID}
                        label={<FormattedMessage {...messages.orgNameLabel} />}
                        helpText={<FormattedMessage {...messages.orgNameDisclaimer} />}
                        placeholder={' '}
                    />
                </FormField>
                <FormField>
                    <TextField
                        fullWidth
                        type="text"
                        isRequired
                        name="referralSource"
                        data-testid="createOrgReferralSource"
                        value={data.referralSource}
                        isClearable={isClearable}
                        onChange={handleFieldChange}
                        errorMessage={intl.formatMessage(messages.referralSourceError)}
                        hasError={fieldStates['referralSource'] === FieldState.INVALID}
                        label={<FormattedMessage {...messages.referralSourceLabel} />}
                        placeholder={' '}
                    />
                </FormField>

                <FormField>
                    <PrimaryButton
                        isFullWidth
                        isLoading={result === CreationResult.LOADING}
                        type="submit"
                        data-testid="create-free-trial-from-email-submit"
                    >
                        <FormattedMessage {...messages.sendEmail} />
                    </PrimaryButton>
                </FormField>
            </form>
        </WhiteBox>
    );
}

const Title = styled(H4)`
    margin-bottom: ${SpacingSmall};
`;

const NamesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    max-width: ${SizeWide};
`;

const NameItem = styled.div`
    flex: 1;
    width: 50%;

    &:first-child {
        padding-right: ${SpacingSmall};
    }

    &:last-child {
        display: flex;
        justify-content: end;
    }
`;

const BannerWrap = styled.div`
    margin-bottom: ${SpacingSmall};
`;
