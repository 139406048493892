import {FindMoreJobOpportunitiesEU} from '../FindMoreJobOpportunitiesEU';
import {Wrapper} from './styled';

import {HighlightedJobs} from '../HighlightedJobs';
import {Props} from './types';

export function ReadinessDataCollectionCard({userId}: Props) {
    return (
        <Wrapper>
            <HighlightedJobs />
            <FindMoreJobOpportunitiesEU userId={userId} forDataCollectionView={false} />
        </Wrapper>
    );
}
