import Downshift, {DownshiftProps} from 'downshift';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {DEFAULT_WIDTH} from '@/componentLibrary/components/inputs/TextField/constants';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {capitalizeFirstLetter} from '@/utils/misc';

import {v4 as uuid} from 'uuid';
import {ActionOverlay} from '../../ActionOverlay';
import {Tag} from '../../Tag';
import {TextFieldHelpText} from '../../inputs/styled';
import {TextFieldErrorMessage} from '../../inputs/styled';
import {ClearSelection} from './components/ClearSelection';
import {CreateCustomItem} from './components/CreateCustomItem';
import {DefaultMenuItem} from './components/DefaultMenuItem';
import {FluidTextField} from './components/FluidTextField';
import {Indicator} from './components/Indicator';
import {InteractField} from './components/InteractField';
import {LabelTags} from './components/InteractField/styled';
import {LoadingState} from './components/LoadingState';
import {Menu} from './components/Menu';
import {
    useDisplayValueLogic,
    useEqualityLogic,
    useFilteringLogic,
    useMenuLogic,
    useSearchStringLogic,
    useSelectLogic
} from './hooks';
import messages from './messages';
import {DropdownTriggerWrapper, FluidTextAction, Wrapper} from './styled';
import {DropdownProps} from './types';
import {emptyFunction, getInputCursor, isObjectOrNull} from './utils';

export function Dropdown<T extends object | string | null, IsMulti extends boolean = false>({
    id,
    label,
    sublabel,
    name,
    helpText,
    errorMessage,
    placeholder,
    'data-testid': dataTestId,
    hasError = false,
    disabled = false,
    readOnly = false,
    isRequired = false,
    fullWidth = false,
    width = DEFAULT_WIDTH,
    onClear,
    isClearable = false,
    menuWidth: menuWidthProp,
    onBlur,
    onFocus,
    zIndex = 999,
    preventClearSearchString = false,
    selectedItem: selectedItemProp,
    isOpen: isOpenProp,
    isItemDisabled,
    customItemComponent,
    onChange,
    allowCreateNewItem = false,
    onCreateNewItem,
    isLoading,
    menuIsLoading,
    items,
    noItemsMessage,
    onMenuScrollToBottom,
    onOuterClick,
    searchString: searchStringProp,
    itemToString: itemToStringProp,
    itemToId: itemToIdProp,
    onSearchStringChange,
    defaultValue = null,
    onSelect,
    components = {},
    disableDefaultFilter = false,
    customFilter = null,
    inputCss = '',
    customItem = null,
    hasIndicator = true,
    forceClose = false,
    noFocusTrap = false,
    placeCustomItemLast = false,
    onOverlayClosed = () => null,
    hideMenu = false,
    isMulti = false as IsMulti,
    showCheckboxes: showCheckboxesProp = false,
    showCheckboxesWithTags = false,
    isSearchable = true,
    size,
    customDisplayValue,
    closeMenuOnSelect = true,
    triggerFocus = false,
    customStateReducer,
    disableMenuScroll = false
}: DropdownProps<T, IsMulti>) {
    const [fallbackId] = useState(id ?? uuid());
    const phrases = useExtractPhraseConstants(messages);
    const {Item = DefaultMenuItem} = components;
    const showCheckboxes = showCheckboxesProp || showCheckboxesWithTags;

    const setOverlayOpenMethod = useRef<(open: boolean) => void | null>();

    const {searchString, handleInputChange, clearSearchString} = useSearchStringLogic({
        controlledSearchString: searchStringProp,
        onSearchStringChange
    });

    const {itemToId} = useEqualityLogic(itemToIdProp);

    const {isFocused, handleOnFocus, handleOnBlur, blur, inputRef, triggerWrapperRef} =
        useMenuLogic(clearSearchString, onBlur, onFocus, isMulti || preventClearSearchString);

    const {handleSelect, handleTagRemove, handleKeyDown, handleTagRemoveAll, selectedItem} =
        useSelectLogic<T, IsMulti>(
            clearSearchString,
            blur,
            defaultValue,
            itemToId,
            closeMenuOnSelect,
            selectedItemProp,
            onSelect,
            onChange,
            isMulti
        );

    const {itemToString, displayValue} = useDisplayValueLogic<T>(
        isFocused,
        showCheckboxes,
        selectedItem,
        searchString,
        itemToStringProp,
        preventClearSearchString,
        customDisplayValue
    );

    const {itemsToShow, isExactMatch, isItemAdded} = useFilteringLogic<T>(
        items,
        disableDefaultFilter,
        searchString,
        isMulti,
        !showCheckboxes,
        isMulti && Array.isArray(selectedItem) ? selectedItem : null,
        itemToId,
        itemToString,
        customFilter
    );

    const openOverlay = useCallback(() => {
        if (!isSearchable) {
            handleOnFocus();
        }
        setOverlayOpenMethod?.current?.(true);
    }, [isSearchable, handleOnFocus]);

    const closeOverlay = useCallback(() => {
        setOverlayOpenMethod?.current?.(false);
    }, []);

    const handleCustomCreateClick = useCallback(async () => {
        if (!allowCreateNewItem || !onCreateNewItem) {
            return;
        }

        const createdItem = await onCreateNewItem(searchString);
        handleSelect(createdItem);
        if (closeMenuOnSelect) {
            closeOverlay();
        }
    }, [
        allowCreateNewItem,
        handleSelect,
        closeMenuOnSelect,
        onCreateNewItem,
        searchString,
        closeOverlay
    ]);

    const handleOverlayClose = useCallback(() => {
        if (!isSearchable) {
            handleOnBlur();
        }
        onOverlayClosed();
    }, [isSearchable, onOverlayClosed, handleOnBlur]);

    const stateReducer = useCallback(
        (prevChanges, changes) => {
            switch (changes.type) {
                case Downshift.stateChangeTypes.keyDownEscape:
                    blur();
                    return {isOpen: false};
                case Downshift.stateChangeTypes.changeInput:
                    if (isMulti && !prevChanges.inputValue && changes.inputValue) {
                        openOverlay();
                    }
                    return {...changes, isOpen: true};
                case Downshift.stateChangeTypes.keyDownEnter: {
                    if (!isMulti || closeMenuOnSelect) {
                        !isMulti && blur();
                        closeOverlay();
                    }

                    if (
                        isMulti &&
                        allowCreateNewItem &&
                        onCreateNewItem &&
                        changes.selectedItem.createCustomItemComponent
                    ) {
                        handleCustomCreateClick();
                    }
                    return {...changes, isOpen: false};
                }
                case Downshift.stateChangeTypes.clickItem:
                    // prevent highlighted item to reset back to 0
                    // when menu doesnt close on selection
                    return {
                        ...changes,
                        highlightedIndex: prevChanges.highlightedIndex
                    };
                default:
                    if (customStateReducer) {
                        return customStateReducer(prevChanges, changes);
                    }
                    return changes;
            }
        },
        [
            blur,
            isMulti,
            customStateReducer,
            openOverlay,
            closeMenuOnSelect,
            allowCreateNewItem,
            onCreateNewItem,
            closeOverlay,
            handleCustomCreateClick
        ]
    );

    const handleClearSelection = useCallback(
        selectItem => {
            selectItem(null);
            onClear?.();
            if (isMulti) {
                handleTagRemoveAll();
            }
        },
        [handleTagRemoveAll, isMulti, onClear]
    );

    const getActionComponent = useCallback(
        (selectItem, isOpen) => {
            if (isLoading) {
                return <LoadingState />;
            }
            const showClear =
                isClearable && (isMulti ? (selectedItem as T[])?.length > 0 : !!selectedItem);

            return (
                <>
                    {showClear && (
                        <ClearSelection
                            onClick={handleClearSelection.bind(null, selectItem)}
                        />
                    )}
                    {hasIndicator && (
                        <Indicator menuIsOpen={isOpen} isDisabled={disabled} onClick={blur} />
                    )}
                </>
            );
        },
        [
            isLoading,
            isMulti,
            selectedItem,
            isClearable,
            handleClearSelection,
            hasIndicator,
            disabled,
            blur
        ]
    );

    useEffect(() => {
        if (forceClose && setOverlayOpenMethod.current) {
            closeOverlay();
        }
    }, [closeOverlay, forceClose]);

    const menuWidth = useMemo(() => {
        if (menuWidthProp) {
            return menuWidthProp;
        }
        if (fullWidth || !isSearchable) {
            return '100%';
        }
        return width;
    }, [menuWidthProp, fullWidth, width, isSearchable]);

    const renderMultiSelectTags = useMemo(() => {
        if (!Array.isArray(selectedItem)) {
            return null;
        }

        return selectedItem.map(item => (
            <Tag
                key={itemToId(item)}
                {...(!disabled
                    ? {
                          $clearable: true,
                          onClear: e => {
                              handleTagRemove(e, item);
                          },
                          onMouseDown: e => {
                              e.preventDefault();
                          }
                      }
                    : null)}
            >
                {itemToString(item)}
            </Tag>
        ));
    }, [handleTagRemove, selectedItem, itemToId, itemToString, disabled]);

    const focusInputEl = useCallback(() => inputRef.current?.focus(), [inputRef]);
    useEffect(() => {
        if (triggerFocus) {
            focusInputEl();
        }
    }, [triggerFocus, focusInputEl]);

    const renderInteractFieldTrigger = useCallback(
        ({isOpen}) => (
            <InteractField<T>
                disabled={disabled}
                isActive={isOpen}
                size={size}
                placeholder={placeholder}
                displayValue={customDisplayValue}
                selectedItem={selectedItem}
                openMenu={openOverlay}
                itemToString={itemToString}
            />
        ),
        [
            customDisplayValue,
            disabled,
            itemToString,
            placeholder,
            selectedItem,
            size,
            openOverlay
        ]
    );

    const renderMultiDropdownTrigger = useCallback(
        ({getInputProps, selectItem, isOpen}) => (
            <DropdownTriggerWrapper
                ref={triggerWrapperRef}
                onFocus={focusInputEl}
                disabled={disabled}
                readOnly={readOnly}
                loading={isLoading}
                multi={isMulti}
            >
                <>
                    {!showCheckboxes && renderMultiSelectTags}
                    {isSearchable && (
                        <FluidTextField
                            {...getInputProps({
                                ref: inputRef,
                                isRequired: isRequired,
                                name: name,
                                onChange: handleInputChange,
                                onFocus: handleOnFocus,
                                onBlur: handleOnBlur,
                                disabled: disabled,
                                readOnly: readOnly,
                                onKeyDown: handleKeyDown
                            })}
                            placeholder={
                                Array.isArray(selectedItem) && !selectedItem.length
                                    ? placeholder ?? phrases.placeholder
                                    : ''
                            }
                            className={hasError ? 'error' : ''}
                            data-testid={dataTestId}
                        />
                    )}
                </>
                <FluidTextAction>{getActionComponent(selectItem, isOpen)}</FluidTextAction>
            </DropdownTriggerWrapper>
        ),
        [
            dataTestId,
            disabled,
            focusInputEl,
            getActionComponent,
            handleInputChange,
            handleKeyDown,
            handleOnBlur,
            handleOnFocus,
            hasError,
            inputRef,
            isLoading,
            isMulti,
            isRequired,
            isSearchable,
            name,
            phrases.placeholder,
            placeholder,
            readOnly,
            renderMultiSelectTags,
            selectedItem,
            showCheckboxes,
            triggerWrapperRef
        ]
    );

    const renderTextFieldTrigger = useCallback(
        ({getInputProps, getLabelProps, selectItem, isOpen}) => (
            <div>
                <TextField
                    {...getInputProps({
                        ref: inputRef,
                        isRequired: isRequired,
                        name: name,
                        onChange: handleInputChange,
                        onFocus: handleOnFocus,
                        onBlur: handleOnBlur,
                        disabled: disabled,
                        readOnly: readOnly,
                        style: {
                            paddingRight: '68px',
                            cursor: getInputCursor(disabled, isFocused),
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'visible'
                        },
                        pointerEvents: isLoading ? 'none' : 'auto'
                    })}
                    label={label}
                    labelProps={getLabelProps({required: isRequired})}
                    sublabel={sublabel}
                    fullWidth={fullWidth}
                    width={width}
                    className={hasError ? 'error' : ''}
                    additionalCss={inputCss}
                    data-testid={dataTestId}
                    placeholder={placeholder ?? phrases.placeholder}
                    actionComponent={getActionComponent(selectItem, isOpen)}
                />
            </div>
        ),
        [
            dataTestId,
            disabled,
            fullWidth,
            getActionComponent,
            handleInputChange,
            handleOnBlur,
            handleOnFocus,
            hasError,
            inputCss,
            inputRef,
            isFocused,
            isLoading,
            isRequired,
            label,
            name,
            phrases.placeholder,
            placeholder,
            readOnly,
            sublabel,
            width
        ]
    );

    const renderDropdownTrigger = useCallback(
        ({getInputProps, getLabelProps, selectItem, isOpen}) => {
            if (isMulti && !showCheckboxes) {
                return renderMultiDropdownTrigger({
                    getInputProps,
                    selectItem,
                    isOpen
                });
            }

            if (!isSearchable) {
                return renderInteractFieldTrigger({isOpen});
            }

            return renderTextFieldTrigger({
                getInputProps,
                getLabelProps,
                selectItem,
                isOpen
            });
        },
        [
            isMulti,
            isSearchable,
            renderInteractFieldTrigger,
            renderMultiDropdownTrigger,
            renderTextFieldTrigger,
            showCheckboxes
        ]
    );

    const renderWithTags = useMemo(() => {
        if (
            isMulti &&
            showCheckboxes &&
            Array.isArray(selectedItem) &&
            selectedItem.length > 0
        ) {
            return (
                <LabelTags>
                    {selectedItem.map(item => (
                        <Tag
                            data-testid={`${dataTestId}-tag`}
                            key={itemToId(item)}
                            onClick={e => handleTagRemove(e, item)}
                            $clearable
                        >
                            {capitalizeFirstLetter(itemToString(item))}
                        </Tag>
                    ))}
                </LabelTags>
            );
        }
    }, [
        dataTestId,
        handleTagRemove,
        isMulti,
        itemToId,
        itemToString,
        selectedItem,
        showCheckboxes
    ]);

    return (
        <Downshift<T>
            id={fallbackId}
            itemToString={itemToString as DownshiftProps<T>['itemToString']}
            onOuterClick={onOuterClick ?? blur}
            onSelect={handleSelect}
            isOpen={isOpenProp ?? isFocused}
            inputValue={displayValue}
            initialHighlightedIndex={0}
            defaultHighlightedIndex={0}
            selectedItem={!isMulti && isObjectOrNull<T>(selectedItem) ? selectedItem : null}
            stateReducer={stateReducer}
        >
            {({
                getRootProps,
                getMenuProps,
                getItemProps,
                getInputProps,
                getLabelProps,
                selectItem,
                isOpen,
                setHighlightedIndex,
                highlightedIndex
            }) => {
                const buildMenu = (setOverlayOpen: (open: boolean) => void) => {
                    setOverlayOpenMethod.current = setOverlayOpen;
                    return (
                        <Menu
                            getMenuProps={getMenuProps}
                            isLoading={menuIsLoading}
                            noItems={
                                (!allowCreateNewItem || isItemAdded) && !itemsToShow?.length
                            }
                            noItemsMessage={noItemsMessage}
                            onScrollToBottom={onMenuScrollToBottom}
                            highlightedIndex={highlightedIndex}
                            setHighlightedIndex={setHighlightedIndex}
                            itemsCount={items?.length || 0}
                            zIndex={zIndex}
                            fullWidth={fullWidth}
                            width={menuWidth}
                            disableScroll={disableMenuScroll}
                        >
                            <>
                                {showCheckboxesWithTags && renderWithTags}
                                {placeCustomItemLast ? null : customItem}
                                {itemsToShow?.map((item, index) => (
                                    <Item
                                        key={`menu-item-${itemToId(item)}`}
                                        index={index}
                                        itemToId={itemToId}
                                        itemToString={itemToString}
                                        item={item}
                                        isDisabled={isItemDisabled?.(item)}
                                        showCheckboxes={isMulti && showCheckboxes}
                                        onClick={
                                            (!showCheckboxes || !isMulti) && closeMenuOnSelect
                                                ? setOverlayOpen.bind(null, false)
                                                : emptyFunction
                                        }
                                        getItemProps={getItemProps}
                                        isSelected={
                                            Array.isArray(selectedItem)
                                                ? selectedItem.some(
                                                      selectedArrayItem =>
                                                          itemToId(item) ===
                                                          itemToId(selectedArrayItem)
                                                  )
                                                : itemToId(item) === itemToId(selectedItem)
                                        }
                                        isHighlighted={index === highlightedIndex}
                                        customComponent={customItemComponent}
                                    />
                                ))}
                                {isMulti &&
                                    allowCreateNewItem &&
                                    searchString.length > 0 &&
                                    !isItemAdded &&
                                    !isExactMatch && (
                                        <CreateCustomItem
                                            index={itemsToShow.length}
                                            getItemProps={getItemProps}
                                            searchString={searchString}
                                            onClick={handleCustomCreateClick}
                                            isHighlighted={
                                                itemsToShow.length === highlightedIndex
                                            }
                                        />
                                    )}

                                {placeCustomItemLast ? customItem : null}
                            </>
                        </Menu>
                    );
                };
                return (
                    <Wrapper
                        fullWidth={fullWidth}
                        width={menuWidth}
                        {...getRootProps({}, {suppressRefError: true})}
                    >
                        <ActionOverlay
                            gap={-5}
                            focusTarget={inputRef}
                            placement="bottom-start"
                            style={{width: menuWidth}}
                            onOverlayClosed={handleOverlayClose}
                            noFocusTrap={noFocusTrap || false}
                            matchTriggerWidth
                            asChild
                            noPadding
                            outsidePress
                            shouldFocus
                            trigger={renderDropdownTrigger({
                                getInputProps,
                                getLabelProps,
                                selectItem,
                                isOpen
                            })}
                            content={disabled || hideMenu ? null : buildMenu}
                        />
                        {!!helpText && !(hasError && errorMessage) && (
                            <TextFieldHelpText>{helpText}</TextFieldHelpText>
                        )}
                        {!!errorMessage && hasError && (
                            <TextFieldErrorMessage>{errorMessage}</TextFieldErrorMessage>
                        )}
                    </Wrapper>
                );
            }}
        </Downshift>
    );
}
