import {SpacingXlarge} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin: 20px 0;
`;

const PersonalityReportWrapper = styled.div`
    margin-top: 40px;
`;

const LeadershipOverviewWrapper = styled.div`
    max-width: 920px;
    margin-top: ${SpacingXlarge};
`;

export {Wrapper, PersonalityReportWrapper, LeadershipOverviewWrapper};
