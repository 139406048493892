import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 20px 0;
    height: 200px;
    width: 260px;
`;

export const WaitingForResult = styled.p`
    font-style: italic;
`;
