import styled from 'styled-components';

import {S2} from '@/componentLibrary/components/typography';
import {
    ColorBaseBlue500,
    ColorBaseBlue600,
    ColorBaseBlue800,
    ColorBgActionSecondaryDisabled,
    ColorFgActionPrimarySecondaryDisabled,
    ColorFgOnEmphasis,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {ButtonProps} from './types';

export const ButtonWrapper = styled.div<ButtonProps>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: ${({isDisabled}) => (isDisabled ? 'not-allowed' : 'pointer')};
    overflow: hidden;
    padding: ${SpacingXxsmall} ${SpacingXxxsmall};
    background-color: ${({isSelected, isDisabled}) => {
        if (isDisabled) {
            return ColorBgActionSecondaryDisabled;
        }
        if (isSelected) {
            return ColorBaseBlue800;
        }
        return ColorBaseBlue500;
    }};
    color: ${({isSelected, isDisabled}) => {
        if (isDisabled) {
            return ColorFgActionPrimarySecondaryDisabled;
        }
        if (isSelected) {
            return ColorFgOnEmphasis;
        }
        return ColorBaseBlue800;
    }};

    &:hover {
        background-color: ${({isSelected, isDisabled}) => {
            if (!isDisabled && !isSelected) {
                return ColorBaseBlue600;
            }
        }};
    }
`;

export const Text = styled(S2)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const HoverWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
