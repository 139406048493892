import styled from 'styled-components';

import {SpacingLarge, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    span {
        width: ${SpacingXxsmall};
        height: 6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    svg {
        min-width: ${SpacingLarge};
        min-height: ${SpacingLarge};
    }
`;
