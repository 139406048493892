import {TableBody} from './components/TableBody';
import {TableHead} from './components/TableHead';
import {useTable} from './hooks';
import {StyledTable, TableWrapper} from './styled';
import {TableProps} from './types';

export function Table<T>({
    data,
    options = {},
    loading,
    rowHover,
    onRowClick,
    ...rest
}: TableProps<T>) {
    const table = useTable({loading, options, data, ...rest});

    return (
        <TableWrapper>
            <StyledTable>
                <TableHead table={table} />
                <TableBody
                    table={table}
                    loading={loading}
                    rowHover={rowHover}
                    onRowClick={onRowClick}
                />
            </StyledTable>
        </TableWrapper>
    );
}
