export enum Icons {
    ADD = 'add',
    ADD_CARD = 'add_card',
    ADD_CIRCLE = 'add_circle',
    ADD_COMMENT = 'add_comment',
    ADD_LINK = 'add_link',
    ALARM = 'alarm',
    ALARM_ADD = 'alarm_add',
    ALARM_OFF = 'alarm_off',
    ALARM_ON = 'alarm_on',
    ANALYTICS = 'analytics',
    APPS = 'apps',
    ARROW_BACK = 'arrow_back',
    ARROW_DOWNWARD = 'arrow_downward',
    ARROW_DROP_DOWN = 'arrow_drop_down',
    ARROW_DROP_UP = 'arrow_drop_up',
    ARROW_FORWARD = 'arrow_forward',
    ARROW_UPWARD = 'arrow_upward',
    ARTICLE = 'article',
    ASSIGNMENT = 'assignment',
    AUTO_AWESOME_MOSAIC = 'auto_awesome_mosaic',
    AUTO_STORIES = 'auto_stories',
    AUTORENEW = 'autorenew',
    BOLT = 'bolt',
    CALENDAR_TODAY = 'calendar_today',
    CANCEL = 'cancel',
    CATEGORY = 'category',
    CELEBRATION = 'celebration',
    CHAT = 'chat',
    CHECK = 'check',
    CHECK_CIRCLE = 'check_circle',
    CHECK_CIRCLE_FILL = 'check_circle_fill',
    CIRCLE = 'circle',
    CLOCK_LOADER_40 = 'clock_loader_40',
    CLOSE = 'close',
    CLOUD_DONE = 'cloud_done',
    CODE_BLOCKS = 'code_blocks',
    CODE = 'code',
    CONTENT_COPY = 'content_copy',
    CREDIT_CARD = 'credit_card',
    CREDIT_SCORE = 'credit_score',
    DASHBOARD = 'dashboard',
    DASHBOARD_CUSTOMIZE = 'dashboard_customize',
    DELETE = 'delete',
    DO_NOT_DISTURB_ON = 'do_not_disturb_on',
    DOMAIN = 'domain',
    DOMAIN_ADD = 'domain_add',
    DONUT_SMALL = 'donut_small',
    DONUT_LARGE = 'donut_large',
    DRAFT = 'draft',
    DRAG_INDICATOR = 'drag_indicator',
    EDIT = 'edit',
    ERROR_CIRCLE_ROUNDED = 'error_circle_rounded',
    EXTENSION = 'extension',
    FILTER_ALT = 'filter_alt',
    FILTER_LIST = 'filter_list',
    FORMAT_BOLD = 'format_bold',
    FORMAT_INDENT_DECREASE = 'format_indent_decrease',
    FORMAT_INDENT_INCREASE = 'format_indent_increase',
    FORMAT_ITALIC = 'format_italic',
    FORMAT_LIST_BULLETED = 'format_list_bulleted',
    FORMAT_LIST_NUMBERED = 'format_list_numbered',
    FORMAT_UNDERLINED = 'format_underlined',
    GOOGLE_LOGO = 'google_logo',
    GRID_VIEW = 'grid_view',
    GROUP = 'group',
    GROUP_ADD = 'group_add',
    HOLDING_HAND = 'holding_hand',
    HELP = 'help',
    HELP_CENTER = 'help_center',
    HOME = 'home',
    INFO = 'info',
    INFO_I = 'info_i',
    INTERESTS = 'interests',
    INVENTORY_2 = 'inventory_2',
    IOS_SHARE = 'ios_share',
    KEYBOARD_ARROW_DOWN = 'keyboard_arrow_down',
    KEYBOARD_ARROW_LEFT = 'keyboard_arrow_left',
    KEYBOARD_ARROW_RIGHT = 'keyboard_arrow_right',
    KEYBOARD_ARROW_UP = 'keyboard_arrow_up',
    KEYBOARD_RETURN = 'keyboard_return',
    LANGUAGE = 'language',
    LEADERBOARD = 'leaderboard',
    LIGHTBULB = 'lightbulb',
    LINK = 'link',
    LIST = 'list',
    LIST_ALT = 'list_alt',
    LINKEDIN_LOGO = 'linkedin_logo',
    LOCATION_ON = 'location_on',
    LOCK = 'lock',
    LOCK_OPEN = 'lock_open',
    LOGIN = 'login',
    LOGOUT = 'logout',
    MAIL = 'mail',
    MENU = 'menu',
    MICROSOFT_LOGO = 'microsoft_logo',
    MORE_HORIZ = 'more_horiz',
    MORE_VERT = 'more_vert',
    MOVING = 'moving',
    NOTIFICATIONS = 'notifications',
    OPEN_IN_NEW = 'open_in_new',
    PERSON = 'person',
    PERSON_ADD = 'person_add',
    PHOTO_CAMERA_FRONT = 'photo_camera_front',
    PLAY_ARROW = 'play_arrow',
    PUBLIC = 'public',
    QUESTION_MARK = 'question_mark',
    REMOVE = 'remove',
    ROCKET = 'rocket',
    ROCKET_LAUNCH = 'rocket_launch',
    SCHEDULE = 'schedule',
    SEARCH = 'search',
    SETTINGS = 'settings',
    SHIELD = 'shield',
    SPARKLES = 'sparkles',
    STAR = 'star',
    TIMER = 'timer',
    TIMER_OFF = 'timer_off',
    TUNE = 'tune',
    UPGRADE = 'upgrade',
    VERIFIED = 'verified',
    VERIFIED_USER = 'verified_user',
    VIEW_WEEK = 'view_week',
    VISIBILITY = 'visibility',
    VISIBILITY_OFF = 'visibility_off',
    WARNING = 'warning',
    WIDGETS = 'widgets',
    OKTA_LOGO = 'okta_logo'
}
