import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {Markdown} from '@/componentLibrary/components/typography/Markdown';

import {ConsentType} from '@/services/consent/types';
import {useStaticLegalContent} from '@/services/staticLegalContent/useStaticLegalContent';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {ConsentTemplate} from './components/ConsentTemplate';
import {ContentWrapper} from './styled';

type Props = {
    type: ConsentType;
};

export function ConsentPage({type}: Props) {
    const {loading, data: consent, error} = useStaticLegalContent({type});

    if (loading) {
        return <LoadingScreen />;
    }

    if (error || !isDefined(consent)) {
        return <ErrorState error={error} />;
    }

    return (
        <ConsentTemplate>
            <ContentWrapper>
                <Markdown withHtmlSupport>{consent.content}</Markdown>
            </ContentWrapper>
        </ConsentTemplate>
    );
}
