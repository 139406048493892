import {SpacingXsmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const WrapperTestComposition = styled.div<{hasOptions?: boolean}>`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxsmall};
    ${({hasOptions}) => `margin-top: ${hasOptions ? SpacingXsmall : SpacingXxsmall};`}
`;

export const WrapperWithoutTests = styled.div`
    margin-top: ${SpacingXxsmall};
`;
