import {Icon} from '@/componentLibrary/components/icons/Icon';

import {Icons} from '../icons/constants';
import {S2} from '../typography';
import {BannerButton} from './components/BannerButton';
import {BannerType} from './constants';
import {useBannerIconAndStyle} from './hooks/useBannerIconAndStyle';
import {
    ActionWrapper,
    BannerWrapper,
    ContentActionWrapper,
    ContentWrapper,
    InnerWrapper
} from './styled';
import {BannerProps} from './types';

export function Banner({
    type = BannerType.INFO,
    children,
    action,
    customAction,
    dismiss,
    customDismiss,
    noIcon,
    noFullwidth,
    title,
    customIcon,
    isPromo,
    ...rest
}: BannerProps) {
    const {bgColor, iconColor, borderColor, icon} = useBannerIconAndStyle({type});

    const noTitle = !title;
    const hasRegularAction = Boolean(action || customAction || dismiss || customDismiss);

    return (
        <BannerWrapper
            bgColor={bgColor}
            borderColor={borderColor}
            noFullwidth={noFullwidth}
            {...rest}
        >
            <InnerWrapper>
                {(!noIcon || !!customIcon) && (
                    <Icon fill={iconColor} icon={customIcon || icon} />
                )}
                <ContentActionWrapper hasRegularAction={hasRegularAction}>
                    <ContentWrapper hasRegularAction={hasRegularAction} noTitle={noTitle}>
                        {!noTitle && <S2>{title}</S2>}
                        {children}
                    </ContentWrapper>
                    <ActionWrapper hasRegularAction={hasRegularAction}>
                        {!!action && <BannerButton isPromo={isPromo} action={action} />}
                        {!!customAction && customAction}
                        {!!dismiss && (
                            <Icon icon={Icons.CLOSE} isHoverable isAction onClick={dismiss} />
                        )}
                        {!!customDismiss && customDismiss}
                    </ActionWrapper>
                </ContentActionWrapper>
            </InnerWrapper>
        </BannerWrapper>
    );
}
