import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {ModalBody} from '@/componentLibrary/blocks/Modals/Modal/styled';
import {ModalHeader} from '@/componentLibrary/components/Modal/ModalHeader';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {FormattedMessage} from 'react-intl';
import {Loader} from './components';
import {useLogic} from './hooks';
import messages from './messages';
import {BranchName, FrameworkName, Header, Wrapper} from './styled';
import {ImportBoilerplateProgressModalProps} from './types';

export function ImportBoilerplateProgressModal({
    onComplete,
    jobPositionId,
    userId,
    modalIsOpenAfterReload
}: ImportBoilerplateProgressModalProps) {
    const phrases = useExtractPhraseConstants(messages);
    const {importDetails, inProgress} = useLogic({
        jobPositionId,
        userId,
        onComplete
    });
    const handleClose = () => undefined;

    return (
        <Modal withoutBody close={handleClose} hideClose>
            <ModalHeader title={phrases.title} hideClose />
            <ModalBody>
                <Wrapper>
                    <Header>{phrases.modalHeader}</Header>
                    <div>
                        <Loader initialState={modalIsOpenAfterReload ? 50 : 0} />
                    </div>
                    {inProgress && importDetails?.framework && importDetails?.branch && (
                        <div>
                            <FormattedMessage
                                values={{
                                    framework: (
                                        <FrameworkName>
                                            {importDetails?.framework}
                                        </FrameworkName>
                                    ),
                                    branch: <BranchName>{importDetails?.branch}</BranchName>
                                }}
                                {...messages.details}
                            />
                        </div>
                    )}
                    <div>{phrases.redirectInfo}</div>
                </Wrapper>
            </ModalBody>
        </Modal>
    );
}
