import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

import {SubtitleS2} from '@/componentLibrary/tokens/typography';
import {
    ColorFgActionTertriaryActive,
    ColorFgActionTertriaryDefault,
    ColorFgActionTertriaryHover,
    ColorFgDefault,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {LinkStyledProps} from './types';

const LinkBaseCss = css<LinkStyledProps>`
    color: ${ColorFgActionTertriaryDefault};
    ${({$noWrap}) => $noWrap && 'white-space: nowrap;'}
    cursor: pointer;

    &:hover {
        color: ${ColorFgActionTertriaryHover};
        text-decoration: ${props => props.$textDecoration || 'underline'};
    }

    &:active {
        color: ${ColorFgActionTertriaryActive};
    }
`;

const UnStyledLinkBaseCss = css<LinkStyledProps>`
    color: ${ColorFgDefault};
    ${({$noWrap}) => $noWrap && 'white-space: nowrap;'}

    &:hover {
        color: ${ColorFgDefault};
        text-decoration: ${props => props.$textDecoration};
    }

    &:active {
        color: ${ColorFgDefault};
    }
`;

export const ExternalLinkBase = styled.a<LinkStyledProps>`
    ${LinkBaseCss};
    display: inline-flex;
    align-items: center;
    gap: ${SpacingXxxsmall};
    ${({$noWrap}) => $noWrap && 'white-space: nowrap;'}
`;

export const HelpCenterLinkBase = styled.a<
    LinkStyledProps & {$inline?: boolean; $hideWhenPrinting?: boolean}
>`
    ${LinkBaseCss}
    ${({$inline}) => ($inline ? 'vertical-align: bottom;' : SubtitleS2)}
    display: inline-flex;
    align-items: center;
    gap: ${({$inline}) => ($inline ? SpacingXxxsmall : SpacingXxsmall)};
    white-space: nowrap;
    ${({$hideWhenPrinting}) => $hideWhenPrinting && '@media print {display: none;}'}
`;

export const LinkBase = styled.a<LinkStyledProps>`
    ${LinkBaseCss}
`;

export const UnStyledLinkBase = styled.a<LinkStyledProps>`
    ${UnStyledLinkBaseCss}
`;

export const StyledReactRouterLink = styled(Link)<LinkStyledProps>`
    ${LinkBaseCss}
`;

export const UnStyledReactRouterLink = styled(Link)<LinkStyledProps>`
    ${UnStyledLinkBaseCss}
`;
