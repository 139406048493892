import styled from 'styled-components';

import {greyColorTokens, secondaryColorTokens} from '@/componentLibrary/tokens/legacyColors';

export const Wrapper = styled.div<{size: number}>`
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${({size}) => size}, 1fr);
`;

export const ItemWrapper = styled.div<{height: number}>`
    display: grid;
    grid-template-rows: 1fr ${({height}) => height}%;
`;

export const Item = styled.div`
    height: 100%;
    width: 90%;
    margin: 0 auto;
    background: ${secondaryColorTokens.purple000()};

    &:hover {
        background: ${greyColorTokens.grey000()};
    }
`;
