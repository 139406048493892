import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {SpacingLarge, SpacingXlarge} from '@/componentLibrary/tokens/variables';

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TitleWrapper = styled.div`
    margin: ${SpacingXlarge} 0;
`;

export const DescriptionWrapper = styled.div`
    ${P2};
    color: ${greyColorTokens.grey200()};
    margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
    padding-top: 12px;
`;

type ImageProps = {
    imageUrl: string | undefined;
};

export const Image = styled.img.attrs<ImageProps>(props => ({
    src: props.imageUrl
}))<ImageProps>`
    height: 100px;
    width: fit-content;
    background: url("${({imageUrl}) => imageUrl}") center center no-repeat;
    background-size: contain;
`;

export const OrganizationTitleWrapper = styled.div`
    margin-top: 8px;
`;

export const PrivacyPolicyNoticeWrapper = styled.div`
    margin-top: ${SpacingLarge};
`;
