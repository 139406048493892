import {useIntl} from 'react-intl';
import {ReactSVG} from 'react-svg';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {S1} from '@/componentLibrary/components/typography';
import {useResendConfirmationEmail} from '@/pages/SignupV2/components/Email/EmailConfirmationSent/hooks/useResendConfirmationEmail';
import {EmailConfirmationSentProps} from '@/pages/SignupV2/components/Email/EmailConfirmationSent/types';

import mailUnreadIcon from './assets/mark-email-unread.svg';
import messages from './messages';
import {CenteredH3, CenteredP1, CenteredWhiteBox} from './styled';

export function EmailConfirmationSent(props: EmailConfirmationSentProps) {
    const {formatMessage} = useIntl();
    const {resendConfirmationEmail, error, success} = useResendConfirmationEmail(props.userId);
    const doResend = () => {
        resendConfirmationEmail();
    };

    return (
        <CenteredWhiteBox>
            {/* @TODO Use proper icon component */}
            <ReactSVG src={mailUnreadIcon} wrapper="span" />
            <CenteredH3>{formatMessage(messages.checkYourInbox)}</CenteredH3>
            <CenteredP1>
                {formatMessage(messages.confirmationNeeded, {
                    email: <S1 data-testid={'success-email-address'}>{props.email}</S1>
                })}
            </CenteredP1>
            {props.userId && (
                <div>
                    <CenteredP1>{formatMessage(messages.resendQuestion)}</CenteredP1>
                    <PlainButton onClick={doResend}>
                        <CenteredP1>{formatMessage(messages.resendLinkText)}</CenteredP1>
                    </PlainButton>
                </div>
            )}

            {(success || error) && (
                <Banner type={success ? BannerType.SUCCESS : BannerType.ERROR}>
                    {success
                        ? formatMessage(messages.resendSuccess)
                        : formatMessage(messages.resendError)}
                </Banner>
            )}
        </CenteredWhiteBox>
    );
}
