import {defineMessages} from 'react-intl';

export default defineMessages({
    header: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstackManual.header'
    },
    headerAnother: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstackManual.headerAnother'
    },
    modalTitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstackManual.modalTitle'
    },
    title: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstackManual.title'
    },
    selectLabel: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstackManual.selectLabel'
    },
    btnLabel: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstackManual.btnLabel'
    }
});
