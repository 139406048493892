import {HighlightedJobCard} from './components/HighlightedJobCard';
import {SAMPLE_GENERIC_JOB_TITLES} from './constants';
import {Wrapper} from './styled';

export const HighlightedJobs = () => {
    const cards = Object.values(SAMPLE_GENERIC_JOB_TITLES).map((value, index) => {
        return <HighlightedJobCard key={index} index={index} title={value} />;
    });

    return <Wrapper>{cards}</Wrapper>;
};
