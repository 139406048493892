import {SYNC_USER_DATA_FROM_LINKEDIN} from '@/api/jobRecommendations/mutations';
import {SyncUserDataFromLinkedinMutation} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {getConfig} from '@/config';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

type DoSyncUserDataFromLinkedinInput = {
    authCode: string;
};

export function useSyncUserDataFromLinkedin(
    userId?: number | null
): MutationHookReturnType<SyncUserDataFromLinkedinMutation, DoSyncUserDataFromLinkedinInput> {
    const [mutate, {error, loading}] = useMutation(SYNC_USER_DATA_FROM_LINKEDIN);
    const redirectUri = getRedirectUrl();

    const syncUserDataFromLinkedin = useCallback(
        ({authCode}: DoSyncUserDataFromLinkedinInput) => {
            if (!isDefined(userId)) {
                return Promise.reject(new Error('User id is required'));
            }

            const variables = {data: {authCode, userId, redirectUri}};
            return mutate({variables});
        },
        [mutate, userId, redirectUri]
    );

    return [syncUserDataFromLinkedin, {error, loading}];
}

function getRedirectUrl() {
    const appConfig = getConfig();
    return `${appConfig.APP}/verify-linkedin-authentication`;
}
