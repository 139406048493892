import {Redirect} from 'react-router-dom';

import {ConsentType} from '@/api/types/__generated__/graphql';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {LINKS} from '@/links';
import {AcceptConsentCheckbox} from '@/services/consent/components/AcceptConsentCheckbox';

import {OrganizationAvatar} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {Caption} from '@/componentLibrary/components/typography';
import {LoadingSpinner} from '../LoadingSpinner';
import {useOrganizationLandingPageLogic} from './logic';
import messages from './messages';
import {
    Blur,
    CheckboxWrapper,
    LeftColumn,
    LeftColumnBody,
    RightColumn,
    TitleWrapper,
    Wrapper
} from './styled';
import {Props} from './types';
import {getJobApplicationLink} from './utils';

export function OrganizationLandingPage({
    organization: {id, name, logo, acceptedByCandidate}
}: Props) {
    const {
        formatMessage,
        loadingUser,
        loadingTermsOfServiceVersion,
        loadingRecruitmentServices,
        loadingCandidateServices,
        user,
        acceptingInvitation,
        registerUserAndGiveConsentsLoading,
        triedToContinue,
        isConsentAccepted,
        acceptConsent,
        acceptOptionalConsent,
        handleSubmit
    } = useOrganizationLandingPageLogic({organization: {id}});

    if (acceptedByCandidate && id) {
        return <Redirect to={getJobApplicationLink(id)} />;
    }
    if (
        !user ||
        loadingUser ||
        loadingTermsOfServiceVersion ||
        loadingRecruitmentServices ||
        loadingCandidateServices
    ) {
        return <LoadingSpinner />;
    }

    return (
        <Wrapper>
            <LeftColumn>
                <LeftColumnBody>
                    <OrganizationAvatar image={logo} name={name ?? ''} />
                    <TitleWrapper>
                        <P1>
                            {formatMessage(messages.title, {name: <strong>{name}</strong>})}
                            &nbsp;
                            {formatMessage(messages.tos, {alva: <strong>Alva</strong>})}
                        </P1>
                    </TitleWrapper>

                    <FlexLayout direction="column">
                        <CheckboxWrapper>
                            <AcceptConsentCheckbox
                                type={ConsentType.TERMS_OF_SERVICE}
                                onChange={acceptConsent}
                                customLabel={formatMessage(
                                    messages.mandatoryConsentCheckboxCustomLabel,
                                    {
                                        termsOfService: (
                                            <ExternalLink url={LINKS.termsOfService}>
                                                {formatMessage(
                                                    messages.alvaTermsOfServiceTitle
                                                )}
                                            </ExternalLink>
                                        ),
                                        privacyPolicy: (
                                            <ExternalLink url={LINKS.privacyPolicy}>
                                                {formatMessage(messages.privacyPolicyTitle)}
                                            </ExternalLink>
                                        )
                                    }
                                )}
                                showRequiredText={triedToContinue && !isConsentAccepted}
                            />
                        </CheckboxWrapper>
                        <AcceptConsentCheckbox
                            type={ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES}
                            linkToFull={LINKS.candidateServices}
                            onChange={acceptOptionalConsent}
                            labelDescription={formatMessage(
                                messages.candidateServicesLabelDescription,
                                {companyName: name}
                            )}
                        />
                    </FlexLayout>

                    <div>
                        <PrimaryButton
                            onClick={handleSubmit}
                            isLoading={
                                acceptingInvitation || registerUserAndGiveConsentsLoading
                            }
                        >
                            {formatMessage(messages.continue)}
                        </PrimaryButton>
                    </div>
                    <Caption>
                        {formatMessage(messages.disclaimer, {
                            email: <strong>{user.email}</strong>
                        })}
                    </Caption>
                </LeftColumnBody>
            </LeftColumn>
            <RightColumn>
                <Blur />
            </RightColumn>
        </Wrapper>
    );
}
