import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SmallSecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {TextFieldLabel} from '@/componentLibrary/components/inputs/styled';
import {P2} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {useCallback, useEffect} from 'react';
import {IMPORT_BOILERPLATE_STATUS} from '../../constants';
import {useTechStackOptions} from '../TechStackSelector/hooks';
import messages from './messages';
import {ModalBody, Wrapper} from './styled';
import {TechStackSelectorManualProps} from './types';

export function TechStackSelectorManual({
    startImport,
    challengeAssignment,
    selectedFramework,
    selectedFrameworkError,
    handleSelectFramework
}: TechStackSelectorManualProps) {
    const phrases = useExtractPhraseConstants(messages);
    const {openModal, closeModal, modalIsOpen} = useSimpleModalLogic();
    const {techStackOptions} = useTechStackOptions({
        challengeAssignment,
        includeManual: false
    });

    const onSubmit = useCallback(() => {
        startImport();
        if (selectedFramework) {
            closeModal();
        }
    }, [closeModal, startImport, selectedFramework]);

    useEffect(() => {
        if (!modalIsOpen && selectedFrameworkError) {
            handleSelectFramework(null);
        }
    }, [modalIsOpen, selectedFrameworkError, handleSelectFramework]);

    if (!challengeAssignment?.challengeSpecifications) {
        return null;
    }

    return (
        <div>
            <SmallSecondaryButton onClick={openModal}>
                {challengeAssignment?.boilerplateImportDetails?.status ===
                IMPORT_BOILERPLATE_STATUS.SUCCESS
                    ? phrases.headerAnother
                    : phrases.header}
            </SmallSecondaryButton>

            {modalIsOpen && (
                <Modal headerTitle={phrases.modalTitle} close={closeModal} withoutBody>
                    <ModalBody>
                        <Wrapper>
                            <P2>{phrases.title}</P2>
                            <P2 forwardedAs="div">
                                <TextFieldLabel isRequired>
                                    {phrases.selectLabel}
                                </TextFieldLabel>
                                <Dropdown
                                    data-testid="tech-stack-manual-selector"
                                    selectedItem={selectedFramework}
                                    onChange={handleSelectFramework}
                                    items={techStackOptions}
                                    hasError={!!selectedFrameworkError}
                                    errorMessage={selectedFrameworkError}
                                />
                            </P2>
                            <PrimaryButton onClick={onSubmit} data-testid="submit-btn">
                                {phrases.btnLabel}
                            </PrimaryButton>
                        </Wrapper>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
}
