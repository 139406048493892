import {useCallback} from 'react';

import {Caption} from '@/componentLibrary/components/typography/Captions';
import {H4} from '@/componentLibrary/components/typography/Headings';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {ArticleWrapper, InnerWrapper} from './styled';
import {ArticleProps} from './types';

export function Article({title, author, url, authorDetails}: ArticleProps) {
    const handleClick = useCallback(() => {
        window.open(url, '_blank', 'noopener, noreferrer');
    }, [url]);

    return (
        <ArticleWrapper onClick={handleClick}>
            <InnerWrapper>
                <H4>{title}</H4>
                <div>
                    <p>{author}</p>
                    <Caption color={ColorFgSubtle}>{authorDetails}</Caption>
                </div>
            </InnerWrapper>
        </ArticleWrapper>
    );
}
