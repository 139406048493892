import styled, {css} from 'styled-components';

import {BorderActionSecondaryDefault} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseRounded,
    ColorBaseGrey600,
    ColorBaseGrey700,
    ColorBaseGrey900,
    ColorBgActionSecondaryDisabled,
    ColorBorderActionSecondaryActive,
    ColorBorderActionSecondaryDisabled,
    ColorBorderActionSecondaryHover,
    FontSizeSmall,
    SpacingMedium,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import {InteractFieldTextProps, InteractFieldWrapperProps} from './types';

export const InteractFieldText = styled.div<InteractFieldTextProps>`
    color: ${ColorBaseGrey700};
    padding-right: ${SpacingXxsmall};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
    }

    &:hover {
        color: ${ColorBaseGrey900};
    }

    ${({isDisabled}) =>
        isDisabled &&
        css`
            &,
            &:hover {
                color: ${ColorBaseGrey600};
            }
        `};
`;

export const InteractFieldWrapper = styled.div<InteractFieldWrapperProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 138px;
    max-width: 100%;
    font-size: ${FontSizeSmall};
    line-height: ${SpacingMedium};
    padding: ${({padding}) => padding};
    border: ${BorderActionSecondaryDefault};
    border-radius: ${BorderRadiusBaseRounded};

    &:hover {
        border-color: ${ColorBorderActionSecondaryHover};

        > ${InteractFieldText} {
            color: ${ColorBaseGrey900};
        }
    }

    ${({isActive}) =>
        isActive &&
        css`
            border-color: ${ColorBorderActionSecondaryActive};

            &:hover {
                border-color: ${ColorBorderActionSecondaryActive};
            }

            > ${InteractFieldText} {
                color: ${ColorBaseGrey900};
            }
        `};

    ${({isDisabled}) =>
        isDisabled &&
        css`
            background-color: ${ColorBgActionSecondaryDisabled};

            &:hover {
                background-color: ${ColorBgActionSecondaryDisabled};
                border-color: ${ColorBorderActionSecondaryDisabled};

                > ${InteractFieldText} {
                    color: ${ColorBaseGrey600};
                }
            }

            cursor: not-allowed;
        `};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
        min-width: initial;
        max-width: initial;
    }
`;

export const LabelTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${SpacingXxxsmall};
    margin: ${SpacingXxsmall};
`;
