import {ElementType, forwardRef} from 'react';
import styled, {css} from 'styled-components';

import {ButtonBase} from '@/componentLibrary/components/buttons/ButtonBase';
import {
    ColorBgActionSecondaryActive,
    ColorBgActionSecondaryDefault,
    ColorBgActionSecondaryDisabled,
    ColorBgActionSecondaryHover,
    ColorBorderActionSecondaryActive,
    ColorBorderActionSecondaryDefault,
    ColorBorderActionSecondaryDisabled,
    ColorBorderActionSecondaryHover,
    ColorFgActionPrimarySecondaryActive,
    ColorFgActionPrimarySecondaryDefault,
    ColorFgActionPrimarySecondaryDisabled,
    ColorFgActionPrimarySecondaryHover
} from '@/componentLibrary/tokens/variables';

import {BUTTON_VARIANTS} from './constants';
import {
    AnchorButtonProps,
    ButtonBaseProps,
    ButtonProps,
    LinkButtonProps,
    NavLinkButtonProps
} from './types';

const secondaryButtonStyles = css<ButtonBaseProps>`
    background-color: ${ColorBgActionSecondaryDefault};
    border-color: ${ColorBorderActionSecondaryDefault};
    color: ${ColorFgActionPrimarySecondaryDefault};

    ${({isLoading}) =>
        isLoading &&
        css`
            &::after {
                border-color: ${ColorBorderActionSecondaryDefault};
                border-radius: 290486px;
                border-right-color: transparent;
                border-top-color: transparent;
            }
        `}

    &:hover {
        background-color: ${ColorBgActionSecondaryHover};
        border-color: ${ColorBorderActionSecondaryHover};
        color: ${ColorFgActionPrimarySecondaryHover};
    }

    &.active,
    &:active {
        background-color: ${ColorBgActionSecondaryActive};
        border-color: ${ColorBorderActionSecondaryActive};
        color: ${ColorFgActionPrimarySecondaryActive};
    }

    ${({isSelected}) =>
        isSelected &&
        css`
            &,
            &:active,
            &:hover {
                background-color: ${ColorBgActionSecondaryActive};
                border-color: ${ColorBorderActionSecondaryActive};
                color: ${ColorFgActionPrimarySecondaryActive};
            }
        `};

    &:disabled {
        background-color: ${ColorBgActionSecondaryDisabled};
        border-color: ${ColorBorderActionSecondaryDisabled};
        color: ${ColorFgActionPrimarySecondaryDisabled};
    }

    &:focus:not(:focus-visible) {
        /* Remove focus indication when a mouse is used */
        outline: none;
    }

    &:focus-visible {
        outline: 2px solid ${ColorBorderActionSecondaryHover};
        outline-offset: 2px;
    }
`;

const StyledSecondaryButton = styled(ButtonBase)<ButtonBaseProps>` 
    ${secondaryButtonStyles}
`;

const {NAVLINK, LINK, ANCHOR} = BUTTON_VARIANTS;

export const SecondaryButton = forwardRef<ElementType, ButtonProps>((props, ref) => (
    <StyledSecondaryButton {...props} ref={ref} />
));

export const SecondaryNavLinkButton = forwardRef<ElementType, NavLinkButtonProps>(
    (props, ref) => <StyledSecondaryButton {...props} ref={ref} variant={NAVLINK} />
);

export const SecondaryLinkButton = forwardRef<ElementType, LinkButtonProps>((props, ref) => (
    <StyledSecondaryButton {...props} ref={ref} variant={LINK} />
));

export const SecondaryAnchorButton = forwardRef<ElementType, AnchorButtonProps>(
    (props, ref) => <StyledSecondaryButton {...props} ref={ref} variant={ANCHOR} />
);

export const SmallSecondaryButton = forwardRef<ElementType, ButtonProps>((props, ref) => (
    <StyledSecondaryButton {...props} ref={ref} small />
));

export const SmallSecondaryNavLinkButton = forwardRef<ElementType, NavLinkButtonProps>(
    (props, ref) => <StyledSecondaryButton {...props} ref={ref} small variant={NAVLINK} />
);

export const SmallSecondaryLinkButton = forwardRef<ElementType, LinkButtonProps>(
    (props, ref) => <StyledSecondaryButton {...props} ref={ref} small variant={LINK} />
);

export const SmallSecondaryAnchorButton = forwardRef<ElementType, AnchorButtonProps>(
    (props, ref) => <StyledSecondaryButton {...props} ref={ref} small variant={ANCHOR} />
);
