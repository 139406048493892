import {
    BorderRadiusBaseLarge,
    ColorBgDefault,
    SpacingLarge,
    SpacingXlarge,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SpacingXxlarge};
    background-color: ${ColorBgDefault};
    padding: ${SpacingXlarge};
    border-radius: ${BorderRadiusBaseLarge};
    max-width: 1000px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingLarge}
`;
