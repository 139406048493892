import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {createColumnHelper} from '@tanstack/react-table';
import messages from '../../messages';
import {TableRow} from './types';

export function useCollectedDataTableLogic() {
    const phrases = useExtractPhraseConstants(messages);
    const {accessor} = createColumnHelper<TableRow>();

    const columns = [
        accessor('title', {
            id: 'title',
            cell: info => info.getValue(),
            header: () => phrases.tableHeaderLinkedinData
        }),
        accessor('description', {
            id: 'description',
            cell: info => info.getValue(),
            header: () => phrases.tableHeaderDescription
        })
    ];

    const data = [
        {
            title: phrases.tableProfileInfoTitle,
            description: phrases.tableProfileInfoDescription
        },
        {
            title: phrases.tableJobPrefsTitle,
            description: phrases.tableJobPrefsDescription
        },
        {
            title: phrases.tableNetworkingTitle,
            description: phrases.tableNetworkingDescription
        }
    ];

    return {columns, data};
}
