import {
    BorderRadiusBaseSmall,
    ColorBaseBlue900,
    ColorBaseGrey200,
    FontSizeDefault,
    SpacingLarge,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0 auto;
    width: 35%;
    max-width: 640px;
    min-width: 420px;
    height: 100vh;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media only screen and (max-width: 760px) {
        width: 100%;
        padding: 36px ${SpacingSmall};
        min-width: 0;
    }

    @media only screen and (min-width: 761px) and (max-width: 800px) {
        width: 100%;
        padding: 36px 80px;
        min-width: 0;
    }
`;

export const BodyWrapper = styled.div`
    margin-top: ${SpacingLarge};
    font-size: ${FontSizeDefault};
`;

export const ButtonsWrapper = styled.div`
    margin-top: ${SpacingXxlarge};
    display: flex;

    & button {
        margin-inline-end: ${SpacingSmall};
    }
`;

export const SpinnerWrapper = styled.div`
    margin-bottom: ${SpacingXxlarge};
`;

export const DescriptionWrapper = styled.div`
    margin-top: ${SpacingSmall};
    font-size: ${FontSizeDefault};
`;

export const LinkWrapper = styled.a`
    color: ${ColorBaseBlue900};
`;

export const Spinner = styled.div`
    border: ${BorderRadiusBaseSmall} solid ${ColorBaseGrey200};
    border-top: ${BorderRadiusBaseSmall} solid ${ColorBaseBlue900};
    border-right: ${BorderRadiusBaseSmall} solid ${ColorBaseBlue900};
    border-left: ${BorderRadiusBaseSmall} solid ${ColorBaseBlue900};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`;
