import {Table} from '@/componentLibrary/components/Table';
import {useCollectedDataTableLogic} from './logic';
import {Wrapper} from './styled';

export function CollectedDataTable() {
    const {columns, data} = useCollectedDataTableLogic();

    return (
        <Wrapper>
            <Table data={data} columns={columns} />
        </Wrapper>
    );
}
