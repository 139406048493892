import {createGlobalStyle, css} from 'styled-components';

import {BodyP2} from './componentLibrary/tokens/typography';
import {
    BorderRadiusBaseSmall,
    ColorBgDefault,
    ColorBgInset,
    ColorFgDefault,
    SpacingMedium,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxxsmall
} from './componentLibrary/tokens/variables';

// Hack to make Prettier format `createGlobalStyle` correctly
const styled = {createGlobalStyle};

const newCssReset = css`
    /***
        The new CSS reset - version 1.8.5 (last updated 14.6.2023)
        GitHub page: https://github.com/elad2412/the-new-css-reset
    ***/

    /*
        Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
        - The "symbol *" part is to solve Firefox SVG sprite bug
        - The "html" attribute is exclud, because otherwise a bug in Chrome breaks the CSS hyphens property (https://github.com/elad2412/the-new-css-reset/issues/36)
    */
    /* stylelint-disable-next-line */
    *:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
        all: unset;
        display: revert;
    }

    /* Preferred box-sizing value */
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    /* Reapply the pointer cursor for anchor tags */
    a,
    button {
        cursor: revert;
    }

    /* Remove list styles (bullets/numbers) */
    ol,
    ul,
    menu {
        list-style: none;
    }

    /* For images to not be able to exceed their container */
    img {
        max-inline-size: 100%;
        max-block-size: 100%;
    }

    /* removes spacing between cells in tables */
    table {
        border-collapse: collapse;
    }

    /* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
    input,
    textarea {
        /* stylelint-disable-next-line */
        -webkit-user-select: auto;
    }

    /* revert the 'white-space' property for textarea elements on Safari */
    textarea {
        white-space: revert;
    }

    /* minimum style to allow to style meter element */
    meter {
        appearance: revert;
    }

    /* preformatted text - use only for this feature */
    &:where(pre) {
        all: revert;
    }

    /* reset default text opacity of input placeholder */
    ::placeholder {
        color: unset;
    }

    /* remove default dot (•) sign */
    ::marker {
        content: initial;
    }

    /* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
    &:where([hidden]) {
        display: none;
    }

    /* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element */
    &:where([contenteditable]:not([contenteditable='false'])) {
        -moz-user-modify: read-write;
        -webkit-user-modify: read-write;
        overflow-wrap: break-word;
        /* stylelint-disable-next-line */
        -webkit-line-break: after-white-space;
        /* stylelint-disable-next-line */
        -webkit-user-select: auto;
    }

    /* apply back the draggable feature - exist only in Chromium and Safari */
    &:where([draggable='true']) {
        -webkit-user-drag: element;
    }

    /* Revert Modal native behavior */
    /* stylelint-disable-next-line */
    &:where(dialog:modal) {
        all: revert;
    }
`;

export const GlobalStyle = styled.createGlobalStyle`
    ${newCssReset}

    html,
    body {
        margin: 0;
        padding: 0;
        background-color: ${ColorBgDefault};
        color: ${ColorFgDefault};
        ${BodyP2}
    }

    html {
        overflow: initial;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizelegibility;
    }

    strong {
        font-weight: 500;
    }

    em {
        font-style: italic;
    }

    code {
        font-family: monospace;
        background-color: ${ColorBgInset};
        border-radius: ${BorderRadiusBaseSmall};
        padding: ${SpacingXxxsmall};
    }

    .alva-bg {
        background: #fafcff center no-repeat;
        background-size: cover;
    }

    table.table {
        background-color: transparent;
    }

    table tr:hover {
        background-color: transparent !important;
    }

    #page {
        position: relative;
        background-color: #fafcff;
    }

    .fixed-navbar #page {
        margin-top: 80px;
    }

    .fixed-sidebar #page,
    .fixed-sidebar #primary-header {
        margin-left: 240px;
    }

    .fixed-sidebar #primary-header .navbar-brand .logo {
        display: none;
    }

    .fixed-sidebar #primary-header .page-title {
        margin-left: 100px;
    }

    .fixed-sidebar .secondary-navigation {
        padding-left: 100px;
    }

    #page.fixed-tabs {
        padding-top: 43px;
    }

    #page .page-content {
        padding: ${SpacingMedium} 88px 120px;
    }

    .fixed-sidebar #page .page-content {
        padding: ${SpacingMedium} 68px 120px;
        max-width: 1200px;
    }

    p + p {
        margin-top: ${SpacingXsmall};
    }

    @media only screen and (max-width: 1440px) {
        .fixed-sidebar #page .page-content {
            padding: ${SpacingMedium} 28px 60px;
        }

        .fixed-sidebar #primary-header .page-title {
            margin-left: 60px;
        }

        .fixed-sidebar .secondary-navigation {
            padding-left: 60px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .fixed-sidebar .sidebar-container {
            display: none;
        }

        .fixed-sidebar #page,
        .fixed-sidebar #primary-header {
            margin-left: 0;
        }

        .fixed-sidebar #primary-header .page-title {
            margin-left: ${SpacingXlarge};
        }

        .fixed-sidebar .secondary-navigation {
            padding-left: ${SpacingXlarge};
        }
    }

    .react-datepicker-popper {
        z-index: 100000;
    }

    @keyframes spin-around {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes fade-in-down {
        from {
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fade-out-down {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }
    }
`;
