import {Fragment, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {LoginPage} from '@/pages/Login';
import {OktaLoginPage} from '@/pages/OktaLogin';
import {NoAccessFallback} from '@/pages/Organization/pages/Settings/components/NoAccessFallback';
import {StripeLoadingPage} from '@/pages/Organization/pages/StripeCheckout/pages/StripeLoadingPage';
import {ResetPasswordPage} from '@/pages/ResetPassword';
import {UpgradePlanPage} from '@/pages/UpgradePlan';
import {UserDemographics} from '@/pages/UserDemographics';
import {RequireScope} from '@/services/auth/RequireScope';

import {VerifyLinkedinAuthentication} from '@/pages/User/pages/VerifyLinkedinAuthentication';
import {LINKS} from './links';
import {AuthenticateCandidate} from './pages/AuthenticateCandidate';
import {ConsentPage} from './pages/ConsentPage';
import {Impersonation} from './pages/Impersonation';
import {InvalidLink} from './pages/InvalidLink';
import {LogicTestDataCollection} from './pages/LogicTestDataCollection';
import {LogicTestIrt} from './pages/LogicTestIrt';
import {LogicTestValidationPage} from './pages/LogicTestValidation';
import {VerifyOidcAuthentication} from './pages/Login/VerifyOidcAuthentication';
import {LogoutPage} from './pages/Logout';
import {NotFound} from './pages/NotFound';
import {PersonalityDataCollection} from './pages/PersonalityDataCollection';
import {PersonalityTestIrt} from './pages/PersonalityTestIrt';
import {PublicJobPosition} from './pages/PublicJobPosition';
import {RecoverPasswordPage} from './pages/RecoverPassword';
import SharedResults from './pages/SharedResults';
import {SignupPage} from './pages/Signup';
import {SignupPage as SignupPageV2} from './pages/SignupV2';
import {StartPage} from './pages/User';
import {requireAuthentication} from './services/auth/RequireAuthentication';
import {DEFAULT_REDIRECT, PERMISSIONS} from './services/auth/constants';
import {CONSENT_TYPES} from './services/consent/consentTypes';
import {lazyRetry} from './utils/lazyRetry';

const Organization = lazyRetry(() => import('./pages/Organization'));

// Wrap pages that require Auth
const WrappedLogicTestIrt = requireAuthentication(LogicTestIrt);
const WrappedOrganization = requireAuthentication(Organization);
const WrappedPersonalityTestIrt = requireAuthentication(PersonalityTestIrt);
const WrappedUser = requireAuthentication(StartPage);

/**
 * THE APP
 * @constructor
 */
export const App = () => {
    return (
        <Fragment>
            <div id="full-screen-modal" key={'full-screen-modal'} />
            <Suspense fallback={<LoadingScreen />}>
                <Switch key={'routes'}>
                    <Route path="/o" component={WrappedOrganization} />
                    <Route path="/auth/candidate" component={AuthenticateCandidate} />
                    <Route path="/start" component={WrappedUser} />
                    <Route path="/apply" component={PublicJobPosition} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path={LINKS.oktaLogin} component={OktaLoginPage} />
                    <Route
                        path="/verify-oidc-authentication"
                        component={VerifyOidcAuthentication}
                    />
                    <Route
                        path="/verify-linkedin-authentication"
                        component={VerifyLinkedinAuthentication}
                    />
                    <Route exact path="/logout" component={LogoutPage} />
                    <Route exact path="/login/recover" component={RecoverPasswordPage} />
                    <Route exact path="/signup" component={SignupPage} />
                    <Route
                        exact
                        path={[
                            LINKS.freeTrialSignup,
                            `${LINKS.freeTrialSignup}/email`,
                            `${LINKS.freeTrialSignup}/sso`,
                            `${LINKS.freeTrialSignup}/done`
                        ]}
                        component={SignupPageV2}
                    />
                    <Route exact path="/reset-password" component={ResetPasswordPage} />
                    <Route exact path="/upgrade/:plan/" component={UpgradePlanPage} />
                    <Route
                        exact
                        path={LINKS.candidateServices}
                        render={() => (
                            <ConsentPage
                                type={CONSENT_TYPES.PRIVACY_POLICY_CANDIDATE_SERVICES}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={[LINKS.privacyPolicy, LINKS.recruitmentServices]}
                        render={() => <ConsentPage type={CONSENT_TYPES.PRIVACY_POLICY} />}
                    />
                    <Route
                        exact
                        path={[LINKS.termsOfService, LINKS.terms]}
                        render={() => <ConsentPage type={CONSENT_TYPES.TERMS_OF_SERVICE} />}
                    />
                    <Route
                        exact
                        path={LINKS.security}
                        render={() => <ConsentPage type={CONSENT_TYPES.SECURITY} />}
                    />
                    <Route
                        exact
                        path={LINKS.dataProcessors}
                        render={() => <ConsentPage type={CONSENT_TYPES.SUB_PROCESSORS} />}
                    />
                    <Route
                        exact
                        path="/sub-processors"
                        render={() => <Redirect to={LINKS.dataProcessors} />}
                    />
                    <Route
                        exact
                        path="/"
                        component={() => <Redirect to={DEFAULT_REDIRECT} />}
                    />
                    <Route path="/logic-test-irt" component={WrappedLogicTestIrt} />
                    <Route path="/logic-test-validation" component={LogicTestValidationPage} />
                    <Route
                        path="/personality-test-irt"
                        component={WrappedPersonalityTestIrt}
                    />
                    <Route exact path="/invalid-link" component={InvalidLink} />
                    <Route exact path="/impersonation/:token" component={Impersonation} />
                    <Route exact path="/demographics-form" component={UserDemographics} />
                    <Route
                        path="/personality-test-data-collection/:localeName"
                        component={PersonalityDataCollection}
                    />
                    <Route
                        path="/logic-test-data-collection/:studyId"
                        component={LogicTestDataCollection}
                    />
                    <Route
                        path="/home"
                        render={props => (
                            <Redirect to={props.location.pathname.replace('/home', '/o')} />
                        )}
                    />
                    <Route
                        exact
                        path="/stripe-checkout-loading"
                        render={() => (
                            <RequireScope
                                fallback={() => <NoAccessFallback />}
                                permissions={[PERMISSIONS.MANAGE_ORGANIZATION_ACCOUNT]}
                            >
                                {() => (
                                    <ErrorBoundary>
                                        <StripeLoadingPage />
                                    </ErrorBoundary>
                                )}
                            </RequireScope>
                        )}
                    />
                    <Route path="/shared-results" component={SharedResults} />
                    <Route exact path="/not-found" component={NotFound} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </Suspense>
        </Fragment>
    );
};
