import {HTMLAttributes} from 'react';
import styled from 'styled-components';

import {BodyP1, BodyP2} from '@/componentLibrary/tokens/typography';

import {KnownTarget} from 'styled-components/dist/types';
import {Base} from './Base';

export type ParagraphProps = {forwardedAs?: KnownTarget} & HTMLAttributes<HTMLSpanElement>;

export const P1 = styled(Base).attrs((props: ParagraphProps) => ({
    as: props.forwardedAs || 'p'
}))`
    ${BodyP1};
`;

export const P2 = styled(Base).attrs((props: ParagraphProps) => ({
    as: props.forwardedAs || 'p'
}))`
    ${BodyP2};
`;
