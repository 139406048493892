import styled, {css} from 'styled-components';

import {BodyP2} from '@/componentLibrary/tokens/typography';
import {ColorBgSubtle, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

import {TABLE_CELL_WIDTH, TABLE_ROW_HEIGHT} from '../../constants';
import {StyledTr} from '../styled';

export const StyledTd = styled.td<{$width?: string}>`
    ${BodyP2}
    height: ${TABLE_ROW_HEIGHT}px;
    padding: ${SpacingXxsmall};
    vertical-align: middle;
    width: ${({$width}) => $width ?? TABLE_CELL_WIDTH.NORMAL};
`;

export const StyledExtendedTr = styled(StyledTr)<{$rowHover?: boolean}>`
    ${({onClick}) => onClick && 'cursor: pointer;'}

    ${({$rowHover}) =>
        $rowHover &&
        css`
            &:hover {
                background-color: ${ColorBgSubtle};

                /* Just because */
                background-image: linear-gradient(
                    135deg,
                    #fbfbfb 25%,
                    #f9f9f9 25%,
                    #f9f9f9 50%,
                    #fbfbfb 50%,
                    #fbfbfb 75%,
                    #f9f9f9 75%,
                    #f9f9f9 100%
                );
                background-size: 33.94px 33.94px;
            }
        `}
`;
