import {useSearchParams} from '@/hooks/useSearchParams';
import {toErrorType} from '@/pages/User/pages/VerifyLinkedinAuthentication/hooks/utils';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useMemo} from 'react';

export const useStateParam = () => {
    const params = useSearchParams();

    return useMemo(() => {
        const encodedState = params.get('state');
        return isDefined(encodedState) ? decodeURIComponent(encodedState) : null;
    }, [params]);
};

export const useAuthCode = () => {
    const params = useSearchParams();
    return useMemo(() => params.get('code'), [params]);
};

export const useError = () => {
    const params = useSearchParams();
    const errorString = useMemo(() => params.get('error'), [params]);
    return toErrorType(errorString);
};
