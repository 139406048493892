import styled from 'styled-components';

import {SpacingSmall, SpacingXxxsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div<{hasSubtitle: boolean}>`
    margin-top: 56px;

    & > &:first-child {
        margin-bottom: ${({hasSubtitle}) => (hasSubtitle ? SpacingXxxsmall : '0')};
    }

    & > &:last-child {
        margin-top: ${SpacingSmall};
    }
`;
