import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    NormalizedCacheObject,
    Operation,
    createHttpLink,
    from
} from '@apollo/client';
import {loadDevMessages, loadErrorMessages} from '@apollo/client/dev';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {GraphQLError} from 'graphql';

import {logger} from '@/services/logrocket';
import {WHITE_LISTED_PATHS} from '@/utils/constants';
import {uniqueArray} from '@/utils/misc';

import {IDEMPOTENT_MUTATIONS, graphqlEndpoint} from './config';
import {buildRequestHeaders} from './utils/ajax';

// Load development-specific messages
loadErrorMessages();
loadDevMessages();

type Fetcher = typeof fetch;

// @ts-ignore
const fetcher: Fetcher = (...args) => fetch(...args);

const httpLink = createHttpLink({
    uri: ({operationName}) => `${graphqlEndpoint}/${operationName}`,
    fetch: fetcher
});

const retryLink = new RetryLink({
    delay: {
        initial: 40
    },
    attempts: {
        retryIf: (error, operation) => {
            const operationDefinition = operation.query.definitions[0];
            if (operationDefinition.kind === 'OperationDefinition') {
                return !!error && operationDefinition.operation !== 'mutation';
            }
            return false;
        }
    }
});

const middlewareLink = new ApolloLink((operation: Operation, forward) => {
    const headers = buildRequestHeaders();

    if (IDEMPOTENT_MUTATIONS.includes(operation.operationName)) {
        // If the request is retried on error we want to reuse the same idempotency key
        const previousHeaders = (operation.getContext().headers || {}) as Record<
            string,
            string
        >;
        const idempotencyKey = previousHeaders['X-Idempotency-Key'] || crypto.randomUUID();
        (headers as Record<string, string>)['X-Idempotency-Key'] = idempotencyKey;
    }

    logger.debug(
        `Making request ${operation.operationName} with correlation id: ${(headers as Record<string, string>)['Alva-Correlation-Id']}`
    );

    operation.setContext({
        headers: headers
    });

    return forward(operation);
});

function isUnauthenticatedError(error: GraphQLError): boolean {
    return error.extensions ? error.extensions.code === 'UNAUTHENTICATED' : false;
}

const checkAuthentication = (errors: readonly GraphQLError[]): void => {
    if (
        Array.isArray(errors) &&
        errors.filter(isUnauthenticatedError).length > 0 &&
        !WHITE_LISTED_PATHS.find(path => window.location.pathname.includes(path))
    ) {
        window.location.href = `/logout?redirect=${window.location.pathname}`;
    }
};

const errorLink = onError(({graphQLErrors, operation, forward}) => {
    if (graphQLErrors) {
        checkAuthentication(graphQLErrors);
    }
    if (IDEMPOTENT_MUTATIONS.includes(operation.operationName)) {
        return forward(operation);
    }
});

const link = from([errorLink, middlewareLink, retryLink, httpLink]);

const cache = new InMemoryCache({
    typePolicies: {
        JobApplication: {
            fields: {
                candidateReminderStatus: {
                    keyArgs: ['jobPositionId', 'userId']
                },
                roleFitV2: {
                    keyArgs: ['jobApplicationId']
                }
            }
        },
        Organization: {
            fields: {
                jobPositionsConnection: {
                    keyArgs: [
                        'fetchOptions',
                        [
                            'searchString',
                            'status',
                            'activationState',
                            'hiringManagerId',
                            'testProfileId',
                            'orderBy',
                            'isDescending'
                        ]
                    ],
                    merge(existing = {}, incoming, {args}) {
                        if (existing?.cursor?.next !== args?.fetchOptions.offset) {
                            return incoming;
                        }
                        const allItems = [...existing.items, ...incoming.items];
                        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                        const toReferenceKey = (item: {[x: string]: any}) => item['__ref'];
                        return {
                            totalResults: incoming.totalResults,
                            cursor: incoming.cursor,
                            items: uniqueArray(allItems, toReferenceKey)
                        };
                    }
                },
                interviewContentLibraryItems: {
                    merge(_, incoming) {
                        return incoming;
                    }
                }
            }
        },
        InterviewTemplate: {
            fields: {
                contentLibraryItems: {
                    merge(_, incoming) {
                        return incoming;
                    }
                }
            }
        },
        InterviewContentLibraryItem: {
            fields: {
                tags: {
                    merge(_, incoming) {
                        return incoming;
                    }
                }
            }
        }
    }
});

export const client = new ApolloClient<NormalizedCacheObject>({
    link: link,
    cache: cache,
    name: 'alva-app',
    version: '1.0'
});
