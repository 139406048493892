import {css} from 'styled-components';

import {
    ColorBgAttention,
    ColorBgDanger,
    ColorBgDefault,
    ColorBgInfo,
    ColorBgSubtle,
    ColorBgSuccess,
    ColorBorderAttention,
    ColorBorderDanger,
    ColorBorderDefault,
    ColorBorderInfo,
    ColorBorderSuccess,
    ColorFgAttention,
    ColorFgDefault,
    ColorFgError,
    ColorFgInfo,
    ColorFgOnEmphasis
} from '@/componentLibrary/tokens/variables';

export enum TagVariant {
    default = 'default',
    info = 'info',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    defaultInverted = 'defaultInverted',
    infoInverted = 'infoInverted',
    successInverted = 'successInverted',
    warningInverted = 'warningInverted',
    dangerInverted = 'dangerInverted'
}

export const VariantColors: {[key in TagVariant]: ReturnType<typeof css>} = {
    [TagVariant.default]: css`
        background-color: ${ColorBgSubtle};
        border-color: ${ColorBorderDefault};
        color: ${ColorFgDefault};
    `,
    [TagVariant.info]: css`
        background-color: ${ColorBgInfo};
        border-color: ${ColorBorderInfo};
        color: ${ColorFgDefault};
    `,
    [TagVariant.success]: css`
        background-color: ${ColorBgSuccess};
        border-color: ${ColorBorderSuccess};
        color: ${ColorFgDefault};
    `,
    [TagVariant.warning]: css`
        background-color: ${ColorBgAttention};
        border-color: ${ColorBorderAttention};
        color: ${ColorFgDefault};
    `,
    [TagVariant.danger]: css`
        background-color: ${ColorBgDanger};
        border-color: ${ColorBorderDanger};
        color: ${ColorFgDefault};
    `,
    [TagVariant.defaultInverted]: css`
        background-color: ${ColorBgDefault};
        border-color: ${ColorFgDefault};
        color: ${ColorFgDefault};
    `,
    [TagVariant.infoInverted]: css`
        background-color: ${ColorFgInfo};
        border-color: ${ColorFgInfo};
        color: ${ColorFgOnEmphasis};
    `,
    [TagVariant.successInverted]: css`
        background-color: ${ColorBorderSuccess};
        border-color: ${ColorBorderSuccess};
        color: ${ColorFgDefault};
    `,
    [TagVariant.warningInverted]: css`
        background-color: ${ColorFgAttention};
        border-color: ${ColorFgAttention};
        color: ${ColorFgDefault};
    `,
    [TagVariant.dangerInverted]: css`
        background-color: ${ColorFgError};
        border-color: ${ColorFgError};
        color: ${ColorFgOnEmphasis};
    `
} as const;
