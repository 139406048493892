import {Redirect, Route, Switch} from 'react-router-dom';

import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {TabBar} from '@/componentLibrary/components/Tabs/TabBar';
import {Tab} from '@/componentLibrary/components/Tabs/TabBar/types';
import {ReactNode} from 'react';

export function renderTabBar(
    shouldRender: boolean,
    tabs: Tab[] | undefined,
    endButton?: ReactNode,
    spotlightTabs = false
) {
    if (shouldRender && tabs) {
        return <TabBar tabs={tabs} endButton={endButton} spotlight={spotlightTabs} />;
    }
    return null;
}

export function renderTabs(
    shouldRender: boolean,
    tabs: Tab[] | undefined,
    redirectPath?: string
) {
    if (shouldRender && tabs) {
        let fallbackRoute = null;
        if (tabs.length > 0 && !redirectPath) {
            fallbackRoute = (
                <Route key={`fallback-page-${tabs[0].id}`}>
                    <ErrorBoundary>{tabs[0].render()}</ErrorBoundary>
                </Route>
            );
        } else if (redirectPath) {
            fallbackRoute = <Redirect to={redirectPath} />;
        }
        return (
            <Switch>
                {tabs?.map(tab => (
                    <Route
                        key={`page-${tab.id}`}
                        exact={tab.isExact !== undefined ? tab.isExact : true}
                        path={tab.routerPath ? tab.routerPath : tab.path}
                    >
                        {tab.render()}
                    </Route>
                ))}
                {fallbackRoute}
            </Switch>
        );
    }
}
