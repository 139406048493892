import {useCallback, useRef, useState} from 'react';

import {ColorFgMuted} from '@/componentLibrary/tokens/variables';
import {useContainerDimensions} from '@/utils/useContainerDimensions';
import {HistogramGrid} from './components/HistogramGrid';
import {ScoreIndicator} from './components/ScoreIndicator';
import {SPACING_BOTTOM, SPACING_LEFT, SPACING_RIGHT, SPACING_TOP} from './constants';
import {useChartLogic} from './hooks';
import {
    Border,
    ContentWrapper,
    GridWrapper,
    Wrapper,
    XAxisLabelWrapper,
    XAxisLabelsWrapper,
    XAxisTitle,
    YAxisLabelWrapper,
    YAxisLabelsWrapper,
    YAxisRightSpacing,
    YAxisTitle,
    YAxisTitleWrapper,
    YAxisTopSpacing
} from './styled';
import {HistogramChartProps} from './types';

export function HistogramChart({
    data,
    score = null,
    xAxisTitle,
    yAxisTitle
}: HistogramChartProps) {
    const chartRef = useRef(null);
    const [refVisible, setRefVisible] = useState(false);
    const {height, width} = useContainerDimensions(chartRef.current, refVisible);

    const triggerRef = useCallback(element => {
        chartRef.current = element;
        setRefVisible(!!element);
    }, []);

    const {
        maxYAxisValue,
        generateXAxisLabels,
        generateYAxisLabels,
        xAxisTickCount,
        yAxisTickCount
    } = useChartLogic({
        data,
        height,
        width,
        spacingTop: SPACING_TOP,
        spacingBottom: SPACING_BOTTOM
    });

    const content = (
        <Wrapper
            spacingLeft={SPACING_LEFT}
            spacingRight={SPACING_RIGHT}
            spacingTop={SPACING_TOP}
            spacingBottom={SPACING_BOTTOM}
        >
            <YAxisTopSpacing>
                <div />
                <Border />
            </YAxisTopSpacing>
            <YAxisRightSpacing>
                <div />
                <Border />
            </YAxisRightSpacing>
            <YAxisLabelsWrapper>
                <YAxisTitleWrapper>
                    <YAxisTitle spacingTop={SPACING_TOP} color={ColorFgMuted}>
                        {yAxisTitle}
                    </YAxisTitle>
                </YAxisTitleWrapper>
                <YAxisLabelWrapper tickCount={yAxisTickCount}>
                    {generateYAxisLabels}
                </YAxisLabelWrapper>
            </YAxisLabelsWrapper>
            <XAxisLabelsWrapper spacingBottom={SPACING_BOTTOM}>
                <XAxisLabelWrapper
                    spacingLeft={SPACING_LEFT}
                    spacingRight={SPACING_RIGHT}
                    tickCount={xAxisTickCount}
                >
                    {generateXAxisLabels(xAxisTickCount)}
                </XAxisLabelWrapper>
                <XAxisTitle
                    spacingLeft={SPACING_LEFT}
                    spacingRight={SPACING_RIGHT}
                    color={ColorFgMuted}
                >
                    {xAxisTitle}
                </XAxisTitle>
            </XAxisLabelsWrapper>
            <GridWrapper>
                <Border />
                <ContentWrapper>
                    <HistogramGrid data={data} maxYAxisValue={maxYAxisValue} />
                    {score !== null && <ScoreIndicator score={score} />}
                </ContentWrapper>
                <Border />
                <Border />
            </GridWrapper>
        </Wrapper>
    );

    return <div ref={triggerRef}>{content}</div>;
}
