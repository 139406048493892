import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseLarge,
    ColorBgDefault,
    SpacingLarge,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${SpacingXxlarge};
    background-color: ${ColorBgDefault};
    padding: ${SpacingLarge};
    border-radius: ${BorderRadiusBaseLarge};
    max-width: 1000px;
    
    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        flex-direction: column;
        
        > :first-child {
            display: none;
        }
    }
`;
