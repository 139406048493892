import {Column, Row, Table} from '@tanstack/react-table';

import {CHECKBOX_VALUES} from '../controls/CheckBox/constants';
import {SORTING_MODE} from '../icons/SortIcon/constants';

export const getColumnSortMode = <T>(table: Table<T>, column: Column<T>) => {
    if (table.getState().sorting[0]?.id !== column.id) {
        return SORTING_MODE.UNDEFINED;
    }
    return column.getNextSortingOrder() === 'asc' ? SORTING_MODE.DESC : SORTING_MODE.ASC;
};

export const getHeaderCheckboxValue = <T>(table: Table<T>) => {
    if (table.getIsAllPageRowsSelected()) {
        return CHECKBOX_VALUES.CHECKED;
    }

    if (table.getIsSomePageRowsSelected()) {
        return CHECKBOX_VALUES.INDETERMINATE;
    }

    return CHECKBOX_VALUES.UNCHECKED;
};

export const getRowCheckboxValue = <T>(row: Row<T>) => {
    if (row.getIsSelected()) {
        return CHECKBOX_VALUES.CHECKED;
    }

    if (row.getIsSomeSelected()) {
        return CHECKBOX_VALUES.INDETERMINATE;
    }

    return CHECKBOX_VALUES.UNCHECKED;
};
