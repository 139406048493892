import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {GetJobPosition_JobPosition} from '@/api/assessment/types';
import {RadioButton} from '@/componentLibrary/components/controls/RadioButton';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {assessmentLinks} from '@/pages/Organization/pages/Assessment/links';

import {TEST, middot} from './constant';
import messages from './messages';
import {WrapperTestComposition, WrapperWithoutTests} from './styled';

type TestCompositionProps = {
    jobPosition: GetJobPosition_JobPosition;
    currentOption: TEST | null;
    onCurrentOptionChange: (value: TEST) => void;
    enabledTests: TEST | null;
};

export const TestComposition = ({
    jobPosition,
    currentOption,
    onCurrentOptionChange,
    enabledTests
}: TestCompositionProps) => {
    const intl = useIntl();

    const getLabel = useCallback(
        option => {
            let psychometricText = '';
            if (jobPosition?.requireLogicIrtTest && jobPosition?.requirePersonalityTest) {
                psychometricText = `${intl.formatMessage(
                    messages.personalityTest
                )}${middot}${intl.formatMessage(messages.logicTest)}`;
            }
            if (jobPosition?.requirePersonalityTest && !jobPosition?.requireLogicIrtTest) {
                psychometricText = intl.formatMessage(messages.personalityTest);
            }
            if (jobPosition?.requireLogicIrtTest && !jobPosition?.requirePersonalityTest) {
                psychometricText = intl.formatMessage(messages.logicTest);
            }
            if (option === TEST.PSYCHOMETRIC) {
                return psychometricText;
            }
            if (option === TEST.CODING) {
                return intl.formatMessage(messages.codingTest);
            }
            if (option === TEST.ALL) {
                return `${psychometricText}${middot}${intl.formatMessage(
                    messages.codingTest
                )}`;
            }
            return '';
        },
        [intl, jobPosition?.requireLogicIrtTest, jobPosition?.requirePersonalityTest]
    );

    const Composition = useMemo(() => {
        if (!jobPosition) {
            return null;
        }
        if (!enabledTests) {
            return (
                <WrapperWithoutTests>
                    {intl.formatMessage(messages.noTests)}
                    &nbsp;
                    <ExternalLink url={assessmentLinks.jobPositionSettings(jobPosition.id)}>
                        {intl.formatMessage(messages.noTestsLink)}
                    </ExternalLink>
                </WrapperWithoutTests>
            );
        }
        if (enabledTests === TEST.ALL) {
            return (
                <WrapperTestComposition hasOptions>
                    <RadioButton<TEST>
                        value={TEST.PSYCHOMETRIC}
                        isSelected={currentOption === TEST.PSYCHOMETRIC}
                        onSelect={onCurrentOptionChange}
                        label={getLabel(TEST.PSYCHOMETRIC)}
                    />
                    <RadioButton<TEST>
                        value={TEST.CODING}
                        isSelected={currentOption === TEST.CODING}
                        onSelect={onCurrentOptionChange}
                        label={getLabel(TEST.CODING)}
                    />
                    <RadioButton<TEST>
                        value={TEST.ALL}
                        isSelected={currentOption === TEST.ALL}
                        onSelect={onCurrentOptionChange}
                        label={getLabel(TEST.ALL)}
                    />
                </WrapperTestComposition>
            );
        }
        return <WrapperTestComposition>{getLabel(enabledTests)}</WrapperTestComposition>;
    }, [currentOption, enabledTests, getLabel, intl, jobPosition, onCurrentOptionChange]);

    return Composition;
};
