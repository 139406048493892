import {
    ColorBgDefault,
    SpacingMedium,
    SpacingSmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const OuterWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${ColorBgDefault};
`;
export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${SpacingSmall};
    padding: ${SpacingSmall} ${SpacingMedium};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;
