import styled from 'styled-components';

export const StyledTable = styled.table`
    width: 100%;
`;

export const TableWrapper = styled.div`
    width: 100%;
    overflow-x: auto;
`;
