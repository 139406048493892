import {
    SpacingMedium,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import rightColumnBackground from './assets/rightColumnBackground.svg';

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        padding: ${SpacingMedium};
    }
`;

export const LeftColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    padding: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        padding: ${SpacingSmall};
    }
`;

export const LeftColumnBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${SpacingSmall};
    max-width: 640px;
`;

export const RightColumn = styled.div`
    position: relative;
    height: 100%;
    width: 650px;
    background: url("${rightColumnBackground}") no-repeat right;
    background-size: 440px;
    
    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        width: 350px;
        background-position: left;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: none;
    }
`;
export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXsmall};

    > p {
        margin: 0;
    }
`;
export const CheckboxWrapper = styled.div`
    margin-bottom: ${SpacingXsmall};
`;

export const Blur = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 320px;
    height: 100%;
    background: rgba(255 255 255 / 33%);
    backdrop-filter: blur(10px);
    z-index: 0;

    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        width: 180px;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: none;
    }
`;
