import {useEffect, useState} from 'react';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {AcceptMarketplaceConsent} from '@/pages/common/marketplace/AcceptMarketplaceConsent';

import {ReadinessDataCollectionCard} from '@/pages/ReadinessDataCollection/components/ReadinessDataCollectionCard';
import {useRelevanceDataLogic} from '@/pages/User/components/JobRecommendations/components/OtherJobsSection/logic/useRelevanceDataLogic';
import {PrivateExperimentalFeatureKeys} from '@/services/experimentalFeatures/features';
import {useUserFeatureFlag} from '@/services/experimentalFeatures/useUserFeatureFlag';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {JobRecommendations} from '../../components/JobRecommendations';
import {useMyApplicationsLogic} from '../MyApplications/hooks';
import {Wrapper} from '../MyOrganizations/styled';

export const MyJobs = () => {
    const {
        loading,
        error,
        refetchUserOrganizations,
        userOrganizations,
        setPollingEnabledAfterAcceptingRecommendation,
        isRightAfterSignup,
        user,
        refetchUser
    } = useMyApplicationsLogic();
    const [reloadingOnSignup, setReloadingOnSignup] = useState(false);

    const {isMissingBasicRelevance, showRelevanceForm} = useRelevanceDataLogic({
        userId: user?.id
    });
    const showUpdateUserRelevanceData = isMissingBasicRelevance && !showRelevanceForm;
    const readinessDataCollectionEnabled = useUserFeatureFlag(
        PrivateExperimentalFeatureKeys.READINESS_DATA_COLLECTION
    );

    useEffect(() => {
        if (userOrganizations.length === 0 && isRightAfterSignup) {
            setReloadingOnSignup(true);
            setPollingEnabledAfterAcceptingRecommendation(true);
        }
    }, [
        isRightAfterSignup,
        userOrganizations.length,
        setPollingEnabledAfterAcceptingRecommendation
    ]);

    if (error) {
        return <ErrorState error={error} refetch={refetchUserOrganizations} />;
    }

    if (loading || (userOrganizations.length === 0 && reloadingOnSignup)) {
        return <LoadingScreen />;
    }

    if (!user?.hasRegisteredToCandidateServices) {
        return (
            <Wrapper>
                <AcceptMarketplaceConsent onAccept={refetchUser} />
            </Wrapper>
        );
    }

    if (readinessDataCollectionEnabled && isDefined(user) && showUpdateUserRelevanceData) {
        return (
            <Wrapper>
                <ReadinessDataCollectionCard userId={user?.id} />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <JobRecommendations
                setPollingEnabledAfterAcceptingRecommendation={
                    setPollingEnabledAfterAcceptingRecommendation
                }
            />
        </Wrapper>
    );
};
