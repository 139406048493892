import {useIntl} from 'react-intl';

import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {ExternalLink} from '@/componentLibrary/components/links';
import {Caption, H2, P1} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {LINKS} from '@/links';
import {HighlightedJobs} from '@/pages/ReadinessDataCollection/components/HighlightedJobs';
import {useAcceptMarketplaceConsentLogic} from './logic';
import messages from './messages';
import {ContentWrapper, Wrapper} from './styled';

type Props = {
    organizationName?: string;
    onAccept?: () => void;
};

export function AcceptMarketplaceConsent({organizationName, onAccept}: Props) {
    const {loadingConsent, acceptingPolicy, acceptPolicy} =
        useAcceptMarketplaceConsentLogic(onAccept);
    const intl = useIntl();
    const phrases = useExtractPhraseConstants(messages);
    const policyDescription = intl.formatMessage(messages.acceptPolicyDescription, {
        companyName: organizationName ?? intl.formatMessage(messages.genericCompanyText)
    });

    return (
        <Wrapper>
            <HighlightedJobs />
            <ContentWrapper>
                <H2>{phrases.acceptPolicyTitle}</H2>
                <P1>{phrases.acceptPolicySubtitle}</P1>
                <Caption>
                    {policyDescription}
                    &nbsp;
                    <ExternalLink url={LINKS.candidateServices}>
                        {phrases.privacyPolicyTitle}
                    </ExternalLink>
                </Caption>

                <PrimaryButton
                    onClick={acceptPolicy}
                    isLoading={acceptingPolicy}
                    disabled={loadingConsent}
                    style={{width: 'fit-content'}}
                >
                    {phrases.acceptPolicyButton}
                </PrimaryButton>
            </ContentWrapper>
        </Wrapper>
    );
}
