import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {H4, P1} from '@/componentLibrary/components/typography';
import {ColorBaseGrey800} from '@/componentLibrary/tokens/variables';
import {roundValue} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {Score, ScoreWrapper, TitleWrapper} from './styled';

export function RoleFitSummary({score}: {score: number | null}) {
    const scoreText = isDefined(score) && score >= 0 ? roundValue(score * 100) : null;

    return (
        <FlexLayout justifyContent="space-between">
            <TitleWrapper>
                <P1>Total Role Fit</P1>
            </TitleWrapper>
            {score !== null ? (
                <ScoreWrapper>
                    <Score>{scoreText}</Score>
                    <H4 color={ColorBaseGrey800}>/100</H4>
                </ScoreWrapper>
            ) : (
                <P1 color={ColorBaseGrey800}>N/A</P1>
            )}
        </FlexLayout>
    );
}
