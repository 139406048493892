import {defineMessages} from 'react-intl';

export default defineMessages({
    acceptPolicyTitle: {
        id: 'app.readinessDataCollectionCTA.title'
    },
    acceptPolicySubtitle: {
        id: 'app.jobRecommendations.excerpt.acceptPolicy.title'
    },
    acceptPolicyDescription: {
        id: 'app.jobRecommendations.excerpt.acceptPolicy.description'
    },
    acceptPolicyButton: {
        id: 'app.jobRecommendations.excerpt.acceptPolicy.button'
    },
    privacyPolicyTitle: {
        id: 'consent.PRIVACY_POLICY.title'
    },
    genericCompanyText: {
        id: 'app.common.acceptMarketplaceConsent.genericCompanyText'
    }
});
