import {getColumnSortMode} from '@/componentLibrary/components/Table/utils';
import {SortIcon} from '@/componentLibrary/components/icons/SortIcon';
import {flexRender} from '@tanstack/react-table';
import {StyledTh} from './styled';
import {TableHeaderProps} from './types';
import {handleSortingClick} from './utils';

export function TableHeader<T>({
    column,
    getContext,
    isPlaceholder,
    table
}: TableHeaderProps<T>) {
    const {enableSorting, meta, header} = column.columnDef;
    const sortingEnabled = enableSorting || meta?.sortingFn;
    const onClick = sortingEnabled ? () => handleSortingClick<T>(column) : undefined;
    return (
        <StyledTh translate="no" onClick={onClick}>
            <div>
                {isPlaceholder ? null : flexRender(header, getContext())}
                {sortingEnabled && <SortIcon mode={getColumnSortMode<T>(table, column)} />}
            </div>
        </StyledTh>
    );
}
