import {useBoilerplateImportDetails} from '@/api/assessment/codingTests/useBoilerplateImportDetails';
import {useCheckAssignmentAccountAccess} from '@/pages/Organization/pages/Assessment/components/CodingTests/hooks/useCheckAccess';
import {getRepositoryUrlWithBranch} from '@/utils/misc';
import {useEffect, useRef, useState} from 'react';
import {IMPORT_BOILERPLATE_STATUS} from '../../constants';
import {updateChallengeAssignmentCache} from '../../utils';
import {LoaderTypeProps, UseLogicProps} from './types';

export function useLogic({
    jobPositionId,
    userId,
    onComplete,
    pollingDuration = 3000
}: UseLogicProps) {
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {checkAssignmentAccountAccess} = useCheckAssignmentAccountAccess();

    const {importDetails, startPolling, stopPolling, challengeAssignmentId, client} =
        useBoilerplateImportDetails({
            jobPositionId,
            userId
        });

    useEffect(() => {
        startPolling(pollingDuration);
    }, [startPolling, pollingDuration]);

    useEffect(() => {
        if (importDetails?.status === IMPORT_BOILERPLATE_STATUS.IN_PROGRESS && !inProgress) {
            setInProgress(true);
        }
    }, [importDetails, inProgress]);

    useEffect(() => {
        (async () => {
            if (
                !inProgress ||
                !importDetails ||
                !challengeAssignmentId ||
                importDetails.status === IMPORT_BOILERPLATE_STATUS.IN_PROGRESS
            ) {
                return;
            }

            stopPolling();
            setInProgress(false);
            onComplete(importDetails);

            if (importDetails.status === IMPORT_BOILERPLATE_STATUS.SUCCESS) {
                updateChallengeAssignmentCache({
                    client,
                    challengeAssignmentId,
                    updatedPayload: {
                        boilerplateImportDetails: importDetails
                    }
                });
                const repositoryUrl =
                    await checkAssignmentAccountAccess(challengeAssignmentId);

                if (repositoryUrl) {
                    window.open(
                        getRepositoryUrlWithBranch(repositoryUrl ?? '', importDetails?.branch),
                        '_blank',
                        'noopener, noreferrer'
                    );
                }
            }
        })();
    }, [
        inProgress,
        importDetails,
        stopPolling,
        checkAssignmentAccountAccess,
        challengeAssignmentId,
        onComplete,
        client
    ]);

    useEffect(() => {
        return () => {
            stopPolling();
        };
    }, [stopPolling]);

    return {importDetails, inProgress};
}

export const useLoader = ({
    intervalDuration,
    incrementFactor,
    maxPercentage = 100,
    initialState = 0
}: LoaderTypeProps) => {
    const [percentage, setPercentage] = useState(initialState);
    const intervalRef = useRef<ReturnType<typeof setInterval>>();

    useEffect(() => {
        initialState && setPercentage(initialState);
    }, [initialState]);

    useEffect(() => {
        const addJitter = (increment: number) => increment + (Math.random() - 0.5) * 0.4;

        const updatePercentage = () => {
            setPercentage(prev => {
                if (prev >= maxPercentage) {
                    intervalRef.current && clearInterval(intervalRef.current);
                    return maxPercentage;
                }

                const progress = prev / maxPercentage;
                const increment = Math.max(
                    (Math.sin(progress * Math.PI) * incrementFactor) / 10 + addJitter(0),
                    0.1
                );
                return Math.min(prev + increment, maxPercentage);
            });

            if (Math.random() < 0.1) {
                intervalRef.current && clearInterval(intervalRef.current);
                setTimeout(() => {
                    intervalRef.current = setInterval(updatePercentage, intervalDuration);
                }, Math.random() * 2000);
            }
        };

        intervalRef.current = setInterval(updatePercentage, intervalDuration);

        return () => intervalRef.current && clearInterval(intervalRef.current);
    }, [intervalDuration, incrementFactor, maxPercentage]);

    return {percentage};
};
