import styled from 'styled-components';

import {H4} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const SectionTitle = styled(H4)`
    margin-block-end: ${SpacingXlarge};
`;

export const SectionWrapper = styled.div`
    @media print {
        &:not(:first-child) {
            page-break-before: always;
            break-before: page;
            page-break-inside: avoid;
            break-inside: avoid;
        }
    }
`;

export const StyledDimensionRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${SpacingXxlarge};
    align-items: flex-start;
    margin-block-end: ${SpacingXlarge};

    @media print {
        page-break-inside: avoid;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: flex;
        flex-direction: column;
        gap: ${SpacingSmall};
    }
`;

export const FactorResult = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};
    padding-top: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
        padding-top: unset;
    }
`;
