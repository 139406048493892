import {gql} from '../types/__generated__';

export const UPDATE_USER_RELEVANCE_INFORMATION = gql(`
    mutation updateUserRelevanceData($data: UpdateUserRelevanceDataInput!) {
        updateUserRelevanceData(data: $data) {
            ok
            errorMessage
            userRelevanceInformation {
                userId
                preferredJobFamily
                interestedInLeadershipPositions
                preferredCompanyTypes
                preferredSpecialties
                preferredIndustries
                preferredCitiesWithMetaData {
                    id
                    name
                    countryName
                }
                spokenLanguages
                updatedAt
            }
        }
    }
`);

export const UPDATE_JOB_RELEVANCE_INFORMATION = gql(`
    mutation updateJobRelevanceData($data: UpdateJobRelevanceDataInput!) {
        updateJobRelevanceData(data: $data) {
            ok
            errorMessage
            jobRelevanceInformation {
                jobPositionId
                citiesWithMetaData {
                    id
                    name
                    countryName
                }
                jobFamily
                jobFamilySpecialties
                levelOfExperience
                hasLeadershipResponsibilities
                requiredLanguages
                roleDescription
                familiarityDescription
            }
        }
    }
`);

export const REJECT_JOB_RECOMMENDATION = gql(`
    mutation rejectJobRecommendation($data: RejectJobRecommendationInput!) {
        rejectJobRecommendation(data: $data) {
            ok
            errorMessage
            jobRecommendationId
        }
    }
`);

export const ACCEPT_JOB_RECOMMENDATION = gql(`
    mutation acceptJobRecommendation($data: AcceptJobRecommendationInput!) {
        acceptJobRecommendation(data: $data) {
            ok
            errorMessage
            jobRecommendationId
        }
    }
`);

export const APPLY_TO_JOB_RECOMMENDATION = gql(`
    mutation applyToJobRecommendation($data: ApplyToJobRecommendationInput!) {
        applyToJobRecommendation(data: $data) {
            ok
            errorMessage
            jobRecommendationId
            userRelevanceInformation {
                userId
                preferredJobFamily
                interestedInLeadershipPositions
                linkedinProfileUrl
                preferredWorkLocations
                preferredCompanyTypes
                preferredSpecialties
                preferredIndustries
                preferredCitiesWithMetaData {
                    id
                    name
                    countryName
                }
                spokenLanguages
                updatedAt
            }
        }
    }
`);

export const UPDATE_JOB_RECOMMENDATION_SETTINGS = gql(`
    mutation updateJobRecommendationSettings($data: UpdateJobRecommendationSettingsInput!) {
        updateJobRecommendationSettings(data: $data) {
            ok
            errorMessage
            jobMarketplaceSettings {
                recommendationsEnabled
            }
        }
    }
`);

export const UPDATE_ORGANIZATION_MARKETPLACE_SETTINGS = gql(`
    mutation updateOrganizationMarketplaceSettings(
        $data: UpdateOrganizationMarketplaceSettingsInput!
    ) {
        updateOrganizationMarketplaceSettings(data: $data) {
            ok
            errorMessage
            organizationMarketplaceSettings {
                organizationId
                autoPublishJobsToMarketplace
            }
        }
    }
`);

export const SYNC_USER_DATA_FROM_LINKEDIN = gql(`
    mutation syncUserDataFromLinkedin($data: SyncUserDataFromLinkedinInput!) {
        syncUserDataFromLinkedin(data: $data) {
            ok
            errorMessage
        }
    }
`);
