import {StyledTr} from '../styled';
import {TableHeader} from './components/TableHeader';
import {TableHeadProps} from './types';

export function TableHead<T>({table}: TableHeadProps<T>) {
    return (
        <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <StyledTr key={headerGroup.id}>
                    {headerGroup.headers.map(({id, column, getContext, isPlaceholder}) => {
                        return (
                            <TableHeader
                                key={id}
                                column={column}
                                table={table}
                                isPlaceholder={isPlaceholder}
                                getContext={getContext}
                            />
                        );
                    })}
                </StyledTr>
            ))}
        </thead>
    );
}
