export enum LINKEDIN_SYNC_STATUS {
    SUCCESS = 'SUCCESS',
    CANCELLED = 'CANCELLED',
    ACCESS_DENIED = 'ACCESS_DENIED',
    FAILED = 'FAILED'
}

export enum LINKEDIN_ERRORS {
    USER_CANCELLED_LOGIN = 'user_cancelled_login',
    USER_CANCELLED_AUTHORIZE = 'user_cancelled_authorize',
    MEMBER_ACCESS_DENIED = 'member_access_denied'
}

export const DEFAULT_REDIRECT = '/start/applications';
