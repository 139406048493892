import {H3} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
    display: flex;
    align-items: flex-end;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: 4px;
    }
`;

export const ScoreWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const Score = styled(H3)`
    margin-right: 4px;
    margin-bottom: 0;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-right: 0;
    }
`;
