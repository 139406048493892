import styled from 'styled-components';

import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal/index';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {H2} from '@/componentLibrary/components/typography/Headings';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';

type Props = {
    title: string;
    description: string;
    children: React.ReactNode;
    loading: boolean;
    close: () => void;
    submit: () => void;
    submitText: string;
};

export function Form({
    loading,
    submit,
    close,
    children,
    title,
    description,
    submitText
}: Props) {
    return (
        <Modal
            headerTitle={' '}
            close={close}
            size={MODAL_SIZE.FULL_PAGE}
            closeOnClickOutside={false}
        >
            <Wrapper>
                <ContentWrapper>
                    <Hero>
                        <H2>{title}</H2>
                        <P1>{description}</P1>
                    </Hero>

                    {children}

                    <PrimaryButtonWrapper>
                        <PrimaryButton isLoading={loading} onClick={submit}>
                            {submitText}
                        </PrimaryButton>
                    </PrimaryButtonWrapper>
                </ContentWrapper>
            </Wrapper>
        </Modal>
    );
}

const Wrapper = styled.div`
    @media only screen and (min-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const ContentWrapper = styled.div`
    max-width: 1156px;
`;

const Hero = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 64px 118px;
    align-items: start;
    justify-content: center;
    background: ${greyColorTokens.grey900()};

    & > * {
        line-height: 135%;
    }

    & > &:not(:last-child) {
        margin-bottom: 16px;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        padding: 20px;
    }
`;

const PrimaryButtonWrapper = styled.div`
    margin-top: 96px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-top: 56px;
    }

    display: flex;
    flex-direction: row;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        flex-direction: column;
    }
`;
