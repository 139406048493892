import styled, {css} from 'styled-components';

import {deviceBreakPointTokens} from '../../tokens/deviceBreakpoints';

type Props = {
    columnsNumber?: number;
    columnMinWidth?: string;
    columnGap?: number;
    rowsNumber?: number;
    rowMinWidth?: string;
    rowGap?: number;
    withHeader?: boolean;
};

export const NColumnsGrid = styled.div<Props>`
    display: grid;
    column-gap: ${props => props.columnGap}px;
    row-gap: ${props => props.rowGap}px;

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        grid-auto-flow: row;
        grid-template-rows: repeat(
            ${props => props.rowsNumber},
            minmax(${props => props.rowMinWidth}, 1fr)
        );
        grid-template-columns: repeat(
            ${props => props.columnsNumber},
            minmax(${props => props.columnMinWidth}, 1fr)
        );
    }

    ${({withHeader}) =>
        withHeader &&
        css`
            & &:first-child {
                grid-column: 1 / -1;
            }
        `}

    @media print {
        grid-auto-flow: row;
        grid-template-rows: repeat(
            ${props => props.rowsNumber},
            minmax(${props => props.rowMinWidth}, 1fr)
        );
        grid-template-columns: repeat(
            ${props => props.columnsNumber},
            minmax(${props => props.columnMinWidth}, 1fr)
        );
    }
`;

NColumnsGrid.defaultProps = {
    columnsNumber: 2,
    columnMinWidth: '1fr',
    columnGap: 16,
    rowsNumber: 2,
    rowMinWidth: '1fr',
    rowGap: 0,
    withHeader: false
};
