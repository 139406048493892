import {useRelevanceParametersOptions} from '@/api/jobRecommendations/useRelevanceParametersOptions';
import {useGetRelevanceInformation} from '@/api/users/useGetRelevanceInformation';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {useIntl} from 'react-intl';
import messages from './messages';
import {CustomPlainButton} from './styled';

export function useFindMoreJobOpportunitiesEULogic(userId: number) {
    const intl = useIntl();
    const phrases = useExtractPhraseConstants(messages);

    const detailedInformationModalLogic = useSimpleModalLogic();
    const relevanceFormModalLogic = useSimpleModalLogic();

    const {relevanceParametersOptions} = useRelevanceParametersOptions();
    const {userRelevanceInformation} = useGetRelevanceInformation(userId);

    const linkedInCaption = intl.formatMessage(messages.linkedInCaption, {
        seeDetailedInformationPlainButton: (
            <CustomPlainButton onClick={detailedInformationModalLogic.openModal}>
                {phrases.seeDetailedInformation}
            </CustomPlainButton>
        )
    });

    return {
        phrases,
        linkedInCaption,
        detailedInformationModalLogic,
        relevanceFormModalLogic,
        relevanceParametersOptions,
        userRelevanceInformation
    };
}
